import { Box, TextField, Create, Grid } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

export const ClientCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("name", { required: "Nombre es obligatorio" })}
              error={!!errors?.name}
              helperText={errors.name?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombre"
              name="name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("email")}
              error={!!errors?.email}
              helperText={errors.email?.message?.toString()}
              margin="normal"
              fullWidth
              id="email"
              label="Correo"
              name="email"
              type={"email"}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
