import { EditOutlined } from "@mui/icons-material";
import { Button, DeleteButton, GridColumns, Stack } from "@pankod/refine-mui";

export const invoicesColumns: GridColumns = [
  {
    field: "invoice",
    headerName: "Factura",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.invoice.invoice;
    },
  },
  {
    field: "date",
    headerName: "Fecha",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.invoice.date_invoice;
    },
  },
  {
    field: "currency",
    headerName: "Moneda",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.invoice.currency;
    },
  },
  {
    field: "amount",
    headerName: "Monto",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return Number(row.amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  {
    field: "qty",
    headerName: "Cantidad de piezas",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "Acciones",
    headerAlign: "center",
    field: "actions",
    minWidth: 180,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <Button>
            <EditOutlined
              fontSize="small"
              onClick={() => row.update(row, false)}
            />
          </Button>
          <DeleteButton
            size="small"
            hideText
            onClick={() => row.delete(row.id)}
          />
        </Stack>
      );
    },
  },
];

export const materialColumns: GridColumns = [
  {
    field: "material",
    headerName: "Material",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.material.code + " - " + params.row.material.name;
    },
  },
  {
    field: "delivered_weight",
    headerName: "Peso Entregado",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "Acciones",
    headerAlign: "center",
    field: "actions",
    minWidth: 180,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <DeleteButton
            size="small"
            hideText
            onClick={() => row.delete(row.id)}
          />
        </Stack>
      );
    },
  },
];
