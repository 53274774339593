import {
  Box,
  TextField,
  Create,
  Grid,
  useAutocomplete,
  Autocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import FileInput from "components/FileInput";
import { uploadFiles } from "functions";
import { IClient } from "interfaces";
import { useState } from "react";

export const CatalogCreate: React.FC = () => {
  const [files, setFiles] = useState([]);
  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    const ids = await uploadFiles(files);

    onFinish({
      ...data,
      files: ids,
    });
  };

  const { autocompleteProps: clientsAutocompleteProps } =
    useAutocomplete<IClient>({
      resource: "clients",
    });

  const remove = (id: number) => {
    const newFiles = files.filter((_: any, index: number) => index !== id);
    setFiles(newFiles);
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="client"
              rules={{ required: "El campo cliente es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...clientsAutocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        clientsAutocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.client}
                        helperText={errors.client?.message?.toString()}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("sku", { required: "Sku es obligatorio" })}
              error={!!errors?.sku}
              helperText={errors.sku?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="name"
              label="Sku"
              name="sku"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("price", { required: "Precio es obligatorio" })}
              error={!!errors?.price}
              helperText={errors.price?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="price"
              label="Precio"
              name="price"
              type={"number"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("description")}
              error={!!errors?.description}
              helperText={errors.description?.message?.toString()}
              margin="normal"
              fullWidth
              id="description"
              label="Descripción"
              name="description"
              multiline
              rows={2}
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FileInput
              setValue={setFiles}
              remove={remove}
              acceptedFiles={files}
              multiple={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
