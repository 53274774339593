import { GridColumns } from "@pankod/refine-mui";
import { SaveOutlined } from "@mui/icons-material";
import { DateField } from "@pankod/refine-mui";

export const finishedPiecesColumns: GridColumns = [
  {
    field: "createdAt",
    headerName: "Fecha",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 130,
    renderCell: (params) => {
      if (!params.row.createdAt) return null;
      return (
        <DateField
          value={params.row.createdAt}
          format={"DD / MMM / YYYY"}
          locales={"es-mx"}
          style={{ textTransform: "uppercase" }}
        />
      );
    },
  },
  {
    field: "employee",
    headerName: "Empleado",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 150,
    renderCell: (params) => {
      return params.row.employee.name;
    },
  },
  {
    field: "qty",
    headerName: "Cantidad",
    headerAlign: "center",
    flex: 1,
    sortable: false,
    filterable: false,
    align: "center",
  },
  {
    field: "real_weight",
    headerName: "Peso Real",
    headerAlign: "center",
    flex: 1,
    sortable: false,
    filterable: false,
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      return params.row.status ? (
        <SaveOutlined color="success" />
      ) : (
        <SaveOutlined color="error" />
      );
    },
  },
];
