import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  esES,
  Grid,
} from "@pankod/refine-mui";
import { IOrder, IProduct } from "interfaces";
import { TextField } from "@pankod/refine-mui";
import { getTodayDate } from "functions";

export const FinishedPiecesList: React.FC = () => {
  const [date, setDate] = React.useState<any>(getTodayDate());
  const { dataGridProps } = useDataGrid<IProduct>({
    resource: `finished-pieces/employees/${date}`,
  });

  const columns = React.useMemo<GridColumns<IOrder>>(
    () => [
      {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "Habilitado",
        headerName: "Hab.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Calado",
        headerName: "Cal.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Soldado",
        headerName: "Sol.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Pulido",
        headerName: "Pul.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Grabado",
        headerName: "Gra.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Detallado",
        headerName: "Det.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Montado",
        headerName: "Mon.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Lustre 2",
        headerName: "Lus2.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Pintado",
        headerName: "Pin.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "Envuelto",
        headerName: "Env.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PiezasTotales",
        headerName: "T. Piezas",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PesoTotal",
        headerName: "T. Peso",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
    ],
    []
  );

  return (
    <List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            margin="normal"
            type={"date"}
            id="date"
            label="Fecha"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Grid>
      </Grid>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        getRowHeight={() => "auto"}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
        hideFooter
      />
      {/* <ModalRender params={currentModal} /> */}
    </List>
  );
};
