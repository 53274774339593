import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  useAutocomplete,
  Create,
  Button,
  List,
  DataGrid,
  esES,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  IAssembledProduct,
  IEmployee,
  IFinishedPieces,
  IOrder,
} from "interfaces";
import { Add, InventoryOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import {
  HttpError,
  useModal,
  useNavigation,
  useOne,
  useTranslate,
} from "@pankod/refine-core";
import { AddOutlined } from "@mui/icons-material";
import { FinishedPiecesModal } from "components/Modals/finished_pieces";
import { finishedPiecesColumns, productColumns } from "./grid_columns";
import { v4 as uuid } from "uuid";
import { EnabledProductModal } from "./modals/products";
import { ClientProductsModal } from "components/Modals/client_products";
import { clientProductColumns } from "pages/order/grid_columns";

type LocationState = {
  recordItemId: string;
};

export const AssembledCreate: React.FC = () => {
  const [products, setProducts] = React.useState<IAssembledProduct[]>([]);
  const [totalPiece, setTotalPiece] = React.useState(0);
  const [finishedPiece, setFinishedPiece] = React.useState<number>(0);
  const [realWeight, setRealWeight] = React.useState<number>(0);

  const [finishedPieces, setFinishedPieces] = React.useState<IFinishedPieces[]>(
    []
  );

  const [prd, setPrd] = React.useState(0);
  const [decrease, setDecrease] = React.useState(0);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      resource: "assembles/create",
      redirect: false,
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("assembles.create.success"),
          type: "success",
        };
      },
    },
  });

  const location = useLocation();
  const { recordItemId } = location.state as LocationState;

  const { data, isLoading, isFetched } = useOne<IOrder, HttpError>({
    resource: "orders",
    id: recordItemId,
    metaData: {
      populate: ["detail"],
    },
  });

  const onSubmit = (data: any) => {
    onFinish({
      ...data,
      order: recordItemId,
      realWeight: realWeight,
      finishedPiece: finishedPiece,
      decrease: decrease,
      finishedPieces: finishedPieces,
      products: products,
    });
  };

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  const {
    show: showProductModal,
    close: closeProductModal,
    visible: visibleProductModal,
  } = useModal();

  const {
    show: showFinishedPiecesModal,
    close: closeFinishedPiecesModal,
    visible: visibleFinishedPiecesModal,
  } = useModal();

  const {
    show: showClientMaterialsModal,
    close: closeClientMaterialsModal,
    visible: visibleClientMaterialsModal,
  } = useModal();

  const handleAddProduct = (
    data: IAssembledProduct,
    another: boolean = false
  ) => {
    if (!another) {
      closeProductModal();
    }
    const productId = uuid();
    const newProduct = {
      id: productId,
      product: data.product,
      quantity_pieces: data.quantity_pieces,
      delete: handleDeleteProduct,
    } as IAssembledProduct;
    setProducts([...products, newProduct]);
  };

  const handleDeleteProduct = (id: string) => {
    const newProducts = products.filter((p) => p.id !== id);
    setProducts(newProducts);
  };

  const productsWithActions = products.map((p) => ({
    ...p,
    delete: handleDeleteProduct,
  }));

  const handleAddFinishedPieces = (newData: any) => {
    const newWeight = newData?.total_real_weight;
    const newFinishedPiece = newData?.total_qty;

    setRealWeight(newWeight);
    setFinishedPiece(newFinishedPiece);

    setFinishedPieces([
      ...finishedPieces,
      {
        ...newData,
        process: "Montado",
        order: recordItemId,
      },
    ]);
  };

  const getDecrease = () => {
    const decrease = prd - realWeight;
    setDecrease(+decrease.toFixed(2));
  };

  useEffect(() => {
    if (!isLoading && isFetched && data?.data?.total_pieces !== undefined) {
      setTotalPiece(data?.data?.total_pieces);
      setPrd(data?.data?.detail?.real_weight || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, isLoading, isFetched]);

  useEffect(() => {
    if (!isLoading && isFetched) {
      getDecrease();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prd, realWeight]);

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
      headerButtons={() => {
        return (
          <>
            <Button
              startIcon={<InventoryOutlined />}
              onClick={() => {
                showClientMaterialsModal();
              }}
            >
              Productos de cliente
            </Button>
          </>
        );
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empleado"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.employee}
                        helperText={errors.employee?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="finished_pieces"
                  label="Pz. Totales"
                  name="finished_pieces"
                  // defaultValue={" "}
                  value={totalPiece || 0}
                  type="number"
                  className="readonly"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="finished_pieces"
                  label="Pz. Terminadas"
                  name="finished_pieces"
                  // defaultValue={0}
                  value={finishedPiece || 0}
                  type="number"
                  className="readonly"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="real_weight"
                  label="PRM"
                  name="real_weight"
                  // defaultValue={0}
                  value={realWeight || 0}
                  className="readonly"
                />
              </Grid>
              <Grid
                item
                xs={3}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Button
                  color={"info"}
                  size={"small"}
                  onClick={() => showFinishedPiecesModal()}
                >
                  <AddOutlined /> Agregar Piezas
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              margin="normal"
              fullWidth
              id="prd"
              label="PRD"
              name="prd"
              value={prd || 0}
              className="readonly"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              margin="normal"
              fullWidth
              id="decrease"
              label="Merma"
              name="decrease"
              value={decrease || 0}
              className="readonly"
            />
          </Grid>
          <FinishedPiecesModal
            visible={visibleFinishedPiecesModal}
            close={closeFinishedPiecesModal}
            title={"Piezas terminadas"}
            data={{
              currentEmployee: getValues("employee"),
              orderQty: totalPiece || 0,
              currentQty: finishedPiece || 0,
              realWeight: realWeight,
            }}
            setData={handleAddFinishedPieces}
            history={{
              config: {
                filters: [
                  {
                    field: "process",
                    operator: "eq",
                    value: "Montado",
                  },
                  {
                    field: "order",
                    operator: "" as any,
                    value: recordItemId,
                  },
                ],
              },
              metaData: {
                populate: ["employee"],
              },
              columns: finishedPiecesColumns,
              extraData: finishedPieces,
            }}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} sm={6}>
          <List
            breadcrumb={false}
            title={"Productos"}
            headerButtons={({ defaultButtons }) => (
              <>
                {defaultButtons}
                <Button
                  startIcon={<Add />}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    showProductModal();
                  }}
                >
                  Agregar
                </Button>
              </>
            )}
            canCreate={false}
          >
            <DataGrid
              rows={productsWithActions}
              hideFooter={true}
              autoHeight
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              columns={productColumns}
            />
            <EnabledProductModal
              visible={visibleProductModal}
              close={closeProductModal}
              title={"Nuevo producto"}
              setData={handleAddProduct}
            />
          </List>
          <ClientProductsModal
            visible={visibleClientMaterialsModal}
            close={closeClientMaterialsModal}
            title={"Productos del cliente"}
            data={{
              order: recordItemId,
            }}
            history={{
              config: {
                filters: [
                  {
                    field: "type",
                    operator: "eq",
                    value: "Entrada",
                  },
                  {
                    field: "order",
                    operator: "" as any,
                    value: recordItemId,
                  },
                ],
              },
              metaData: {
                populate: ["order", "product"],
              },
              resource: "products-data",
              columns: clientProductColumns,
            }}
          />
        </Grid>
      </Box>
    </Create>
  );
};
