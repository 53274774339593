import { Box, TextField, Create, Grid, Autocomplete } from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";

export const ProductCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("code", { required: "Código es obligatorio" })}
              error={!!errors?.code}
              helperText={errors.code?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="code"
              label="Código"
              name="code"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("name", { required: "Nombre es obligatorio" })}
              error={!!errors?.name}
              helperText={errors.name?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombre"
              name="name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="measure"
              rules={{ required: "Medida es obligatoria" }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={["Piezas", "Litros", "Gramos"]}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Medida"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.measure}
                          helperText={errors.measure?.message?.toString()}
                          required
                          autoFocus
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("minimum_stock", {
                required: "Stock mínimo es obligatorio",
              })}
              error={!!errors?.minimum_stock}
              helperText={errors.minimum_stock?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="minimum_stock"
              label="Stock mínimo"
              name="minimum_stock"
              type={"number"}
              defaultValue={0}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
