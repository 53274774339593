import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  DeleteButton,
  List,
  Stack,
  esES,
  EditButton,
} from "@pankod/refine-mui";
import { IRecovery } from "interfaces";

export const RecoveryList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IRecovery>({
    metaData: {
      populate: ["employee", "order"],
    },
  });

  const columns = React.useMemo<GridColumns<IRecovery>>(
    () => [
      {
        field: "material",
        headerName: "Material",
        flex: 1,
      },
      {
        field: "delivered",
        headerName: "Peso entregado",
        flex: 1,
      },
      { field: "recovered", headerName: "Peso regresado", flex: 1 },
      {
        field: "difference",
        headerName: "Diferencia",
        flex: 1,
        valueGetter: (params) => {
          if (!params?.row?.delivered || !params?.row?.recovered) return;
          return (params?.row?.delivered - params?.row?.recovered).toFixed(2);
        },
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
