import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  DataGrid,
  DialogActions,
  esES,
  Grid,
  List,
  SaveButton,
  TextField,
} from "@pankod/refine-mui";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useAutocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IEmployee } from "interfaces";
import { IFinishedPieceModalProps, IuseFinisedPieces } from "../interfaces";
import { useFinisedPieces } from "../hooks/useFinisedPieces";
import { useList } from "@pankod/refine-core";
import { v4 as uuid } from "uuid";
import _ from "lodash-es";
import { getTodayDate } from "functions";

export const FinishedPiecesSimpleModal: React.FC<IFinishedPieceModalProps> = ({
  visible,
  close,
  title,
  data,
  setData,
  history,
}) => {
  const {
    saveButtonProps,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    refineCoreProps: {
      resource: "null",
    },
  });

  const { data: { data: historyData } = { data: [] }, isLoading } =
    useList<any>({
      resource: history?.resource || "finished-pieces",
      queryOptions: {
        enabled: visible && !!history,
      },
      config: history?.config,
      metaData: history?.metaData,
    });

  const { autocompleteProps: employeeAutocompleteProps } =
    useAutocomplete<IEmployee>({
      resource: "employees",
      filters: [{ field: "active", operator: "eq", value: true }],
      onSearch: (search) => [
        {
          field: "name",
          operator: "contains",
          value: search,
        },
      ],
    });

  const cleanInputs = () => {
    reset();
  };

  const { orderQty, currentQty, totalQty } = useFinisedPieces({
    orderQty: data?.orderQty,
    currentQty: data?.currentQty,
    newQty: watch("qty") ? +watch("qty") : 0,
  }) as IuseFinisedPieces;

  const onSubmit = (submitData: any) => {
    let newData = {
      ...submitData,
      qty: +Number(submitData.qty).toFixed(0),
      total_qty: totalQty,
    };
    setData(newData);
    cleanInputs();
  };

  useEffect(() => {
    if (visible) {
      setValue("employee", data?.currentEmployee);
      setValue("qty", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, visible]);

  const extraData = (history?.extraData || []).map((item: any) => {
    return {
      id: uuid(),
      date: item.date,
      employee: item.employee,
      process: item.process,
      qty: +item.qty,
      //   real_weight: +item.real_weight,
    };
  });

  const historyWithStatus = [...historyData, ...extraData]?.map((e) => ({
    ...e,
    status: !_.includes(e?.id?.toString(), "-"),
  }));

  return (
    <Dialog open={visible} onClose={close} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("date", {
                  required: "El campo fecha es obligatorio.",
                })}
                error={!!errors?.date}
                helperText={errors.date?.message?.toString()}
                margin="normal"
                fullWidth
                type={"date"}
                id="date"
                label="Fecha"
                name="date"
                defaultValue={getTodayDate()}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="employee"
                rules={{ required: "El campo empleado es obligatorio." }}
                defaultValue={null as any}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...employeeAutocompleteProps}
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      getOptionLabel={(item) => {
                        return (
                          employeeAutocompleteProps?.options?.find(
                            (p) => p?.id?.toString() === item?.id?.toString()
                          )?.name ?? ""
                        );
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return (
                          value === undefined ||
                          option.id.toString() === value.id.toString()
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Empleado"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.employee}
                          helperText={errors.employee?.message?.toString()}
                          required
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("qty", {
                  required: "El campo cantidad es obligatorio.",
                })}
                error={!!errors?.qty}
                helperText={errors.qty?.message?.toString()}
                margin="normal"
                fullWidth
                id="qty"
                label="Cantidad"
                name="qty"
                type={"number"}
                required
                defaultValue={""}
              />
              <div>
                {orderQty} / {currentQty} / {totalQty}
              </div>
            </Grid>
            <Grid container justifyContent="flex-end" marginTop={2}>
              <SaveButton
                {...saveButtonProps}
                onClick={(e: React.BaseSyntheticEvent) => {
                  e.preventDefault();
                  handleSubmit(
                    (data) => onSubmit(data),
                    () => false
                  )(e);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <List breadcrumb={false} title={"Historial"} canCreate={false}>
                <DataGrid
                  rows={historyWithStatus}
                  autoHeight
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  pageSize={10}
                  columns={history?.columns || []}
                  loading={isLoading}
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      textOverflow: "clip",
                      whiteSpace: "break-spaces",
                      lineHeight: 1,
                    },
                  }}
                />
              </List>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
