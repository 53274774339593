import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  DeleteButton,
  List,
  Stack,
  esES,
  EditButton,
  DateField,
} from "@pankod/refine-mui";
import { IEmptying } from "interfaces";

export const EmptyingList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IEmptying>({
    metaData: {
      populate: ["employee"],
    },
  });

  const columns = React.useMemo<GridColumns<IEmptying>>(
    () => [
      {
        field: "createdAt",
        headerName: "Fecha",
        width: 150,
        renderCell: function render({ row }) {
          if (!row.createdAt) return "Sin fecha";
          return (
            <DateField
              value={row.createdAt}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      {
        field: "employee",
        headerName: "Empleado",
        width: 150,
        valueGetter: (params) => params?.row?.employee?.name || "",
      },
      {
        field: "material_delivered",
        headerName: "Material entregado",
        width: 150,
        renderCell: function render({ row }) {
          return (+row.decrease + +row.material_returned).toFixed(2);
        },
      },
      {
        field: "material_returned",
        headerName: "Material regresado",
        width: 150,
        renderCell: function render({ row }) {
          return row.material_returned.toFixed(2);
        },
      },
      {
        field: "decrease",
        headerName: "Merma",
        width: 150,
        renderCell: function render({ row }) {
          return row.decrease.toFixed(2);
        },
      },
      {
        field: "encapsulated_pipes",
        headerName: "Tubos enfrascados",
        width: 150,
      },
      {
        field: "quantity_per_pipe",
        headerName: "Piezas x tubo",
        width: 150,
      },
      {
        field: "returned_pieces",
        headerName: "Piezas regresadas",
        width: 150,
      },
      {
        field: "difference",
        headerName: "Diferencia",
        width: 150,
        renderCell: function render({ row }) {
          return +row.quantity_per_pipe - +row.returned_pieces;
        },
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {/* <EditButton size="small" hideText recordItemId={params.row.id} /> */}
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
