import { useEffect, useState } from "react";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Paper,
} from "@pankod/refine-mui";
import { getTodayDate, uploadFiles } from "functions";
import FileInput from "components/FileInput";
import { Previewers } from "components/Previewers";

export const EmployeeEdit: React.FC = () => {
  const [jobApplicationFiles, setJobApplicationFiles] = useState([]);
  const [contractFiles, setContractFiles] = useState([]);
  const [regulationFiles, setRegulationFiles] = useState([]);
  const [proofOfAddressFiles, setProofOfAddressFiles] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    refineCore: { formLoading, onFinish, queryResult },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      metaData: {
        populate: [
          "job_application",
          "contract",
          "regulation",
          "proof_of_address",
          "photo",
        ],
      },
    },
  });

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    const jobApplicationIds = await uploadFiles(jobApplicationFiles);
    const contractIds = await uploadFiles(contractFiles);
    const regulationIds = await uploadFiles(regulationFiles);
    const proofOfAddressIds = await uploadFiles(proofOfAddressFiles);
    const photoIds = await uploadFiles(photoFiles);
    onFinish({
      ...data,
      job_application: jobApplicationIds,
      contract: contractIds,
      regulation: regulationIds,
      proof_of_address: proofOfAddressIds,
      photo: photoIds,
      confirmed: true,
    });
  };

  const remove = (id: number, type: string) => {
    let newFiles = [];
    switch (type) {
      case "job_application":
        newFiles = jobApplicationFiles.filter(
          (_: any, index: number) => index !== id
        );
        setJobApplicationFiles(newFiles);
        break;
      case "contract":
        newFiles = contractFiles.filter(
          (_: any, index: number) => index !== id
        );
        setContractFiles(newFiles);
        break;
      case "regulation":
        newFiles = regulationFiles.filter(
          (_: any, index: number) => index !== id
        );
        setRegulationFiles(newFiles);
        break;
      case "proof_of_address":
        newFiles = proofOfAddressFiles.filter(
          (_: any, index: number) => index !== id
        );
        setProofOfAddressFiles(newFiles);
        break;
      case "photo":
        newFiles = photoFiles.filter((_item: any, index: number) => {
          return index !== id;
        });
        setPhotoFiles(newFiles);
        break;
      default:
        break;
    }
  };

  const populateFiles = (data: any, setData: any) => {
    if (data) {
      let files = [];
      if (Array.isArray(data)) {
        files = data.map((file) => {
          return {
            id: file?.id,
            path: file?.name,
            size: file?.size * 1024,
            url: file?.url,
            ext: file?.ext,
          };
        });
      } else {
        const file = {
          id: data?.id,
          path: data?.name,
          size: data?.size * 1024,
          url: data?.url,
          ext: data?.ext,
        };
        files.push(file);
      }
      setData(files);
    }
    return [];
  };

  useEffect(() => {
    populateFiles(
      queryResult?.data?.data?.job_application,
      setJobApplicationFiles
    );
    populateFiles(queryResult?.data?.data?.contract, setContractFiles);
    populateFiles(queryResult?.data?.data?.regulation, setRegulationFiles);
    populateFiles(
      queryResult?.data?.data?.proof_of_address,
      setProofOfAddressFiles
    );
    populateFiles(queryResult?.data?.data?.photo, setPhotoFiles);
  }, [queryResult?.data?.data]);

  return (
    <Edit
      isLoading={formLoading || isSubmitting}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <Box
              component="fieldset"
              style={{
                borderColor: "#c4c4c4",
                borderWidth: 0,
                borderStyle: "solid",
                borderRadius: 5,
              }}
            >
              <legend style={{ color: "#9f9e9e" }}>{"Activo"}</legend>
              <FormGroup>
                <Controller
                  name="active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} onChange={onChange} />}
                      label=""
                    />
                  )}
                />
              </FormGroup>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("name", { required: "Nombre es obligatorio" })}
              error={!!errors?.name}
              helperText={errors.name?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombre"
              name="name"
              defaultValue={" "}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("email", {
                required: "Correo es obligatorio",
                validate: (value) => {
                  return value?.includes("@") || "Correo no válido";
                },
              })}
              error={!!errors?.email}
              helperText={errors.email?.message?.toString()}
              margin="normal"
              fullWidth
              id="email"
              label="Correo"
              name="email"
              required
              defaultValue={" "}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12 / 2}>
            <Paper elevation={1} style={{ padding: 10 }}>
              <Typography variant="h6" gutterBottom>
                Solicitud
              </Typography>
              <FileInput
                setValue={setJobApplicationFiles}
                remove={(value: any) => {
                  remove(value, "job_application");
                }}
                acceptedFiles={jobApplicationFiles}
                multiple={false}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12 / 2}>
            <Paper elevation={1} style={{ padding: 10 }}>
              <Typography variant="h6" gutterBottom>
                Contrato
              </Typography>
              <FileInput
                setValue={setContractFiles}
                remove={(value: any) => {
                  remove(value, "contract");
                }}
                acceptedFiles={contractFiles}
                multiple={false}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12 / 2}>
            <Paper elevation={1} style={{ padding: 10 }}>
              <Typography variant="h6" gutterBottom>
                Reglamento
              </Typography>
              <FileInput
                setValue={setRegulationFiles}
                remove={(value: any) => {
                  remove(value, "regulation");
                }}
                acceptedFiles={regulationFiles}
                multiple={false}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12 / 2}>
            <Paper elevation={1} style={{ padding: 10 }}>
              <Typography variant="h6" gutterBottom>
                Comprobante
              </Typography>
              <FileInput
                setValue={setProofOfAddressFiles}
                remove={(value: any) => {
                  remove(value, "proof_of_address");
                }}
                acceptedFiles={proofOfAddressFiles}
                multiple={false}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12 / 2}>
            <Paper elevation={1} style={{ padding: 10 }}>
              <Typography variant="h6" gutterBottom>
                Foto
              </Typography>
              <FileInput
                setValue={setPhotoFiles}
                remove={(value: any) => {
                  remove(value, "photo");
                }}
                acceptedFiles={photoFiles}
                multiple={false}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={12 / 4}>
            <TextField
              {...register("date_of_entry")}
              error={!!errors?.date_of_entry}
              helperText={errors.date_of_entry?.message?.toString()}
              margin="normal"
              fullWidth
              type={"date"}
              id="date_of_entry"
              label="Fecha de ingreso"
              name="date_of_entry"
              defaultValue={getTodayDate()}
            />
          </Grid>
          <Grid item xs={12} sm={12 / 4}>
            <TextField
              {...register("phone")}
              error={!!errors?.phone}
              helperText={errors.phone?.message?.toString()}
              margin="normal"
              fullWidth
              id="phone"
              label="Telefono"
              name="phone"
              defaultValue={" "}
            />
          </Grid>
          <Grid item xs={12} sm={12 / 4}>
            <TextField
              {...register("emergency_phone")}
              error={!!errors?.emergency_phone}
              helperText={errors.emergency_phone?.message?.toString()}
              margin="normal"
              fullWidth
              id="emergency_phone"
              label="Tel. Emergencia"
              name="emergency_phone"
              defaultValue={" "}
            />
          </Grid>
          <Grid item xs={12} sm={12 / 4}>
            <TextField
              {...register("nss")}
              error={!!errors?.nss}
              helperText={errors.nss?.message?.toString()}
              margin="normal"
              fullWidth
              id="nss"
              label="NSS"
              name="nss"
              defaultValue={" "}
            />
          </Grid>
        </Grid>
      </Box>
      <Previewers
        files={[
          ...jobApplicationFiles,
          ...contractFiles,
          ...regulationFiles,
          ...proofOfAddressFiles,
          ...photoFiles,
        ]}
      />
    </Edit>
  );
};
