import { HttpError, useCustom } from "@pankod/refine-core";
import { Box, TextField, Create, Grid, Autocomplete } from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { API_URL } from "../../constants";
import { IRecovery } from "interfaces";
import { useEffect, useState } from "react";

const excessMaterial = ["Oro", "Plata", "Cobre", "Overlay"];

const TYPES = {
  Plata: "silver",
  Oro: "gold",
  Overlay: "overlay",
  Cobre: "copper",
};

export const RecoveryCreate: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState(excessMaterial[0]);
  const [recoveryData, setRecoveryData] = useState<IRecovery | null>({
    total: 0,
    totalMaterial: 0,
    difference: 0,
  });

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRecovery, HttpError, IRecovery>();

  const { data: recovery } = useCustom<any>({
    url: `${API_URL}/api/recoveries/1`,
    method: "get",
  });

  useEffect(() => {
    if (recovery) {
      const data = recovery?.data?.data?.attributes;
      const material = currentMaterial;
      const totalMaterial = TYPES[material.toString() as keyof typeof TYPES];
      setRecoveryData({
        total: data?.total ?? 0,
        totalMaterial: data?.[totalMaterial] ?? 0,
      });
    }
  }, [recovery, currentMaterial]);

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    onFinish({
      ...data,
    });
  };

  return (
    <Create
      isLoading={formLoading || isSubmitting}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              fullWidth
              id="total"
              label="Sobrante general"
              name="total"
              value={(
                (recoveryData?.total ?? 0) - (watch("delivered") ?? 0)
              ).toFixed(2)}
              className="readonly"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="material"
              rules={{ required: "Moneda es obligatoria" }}
              defaultValue={currentMaterial as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={excessMaterial}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      setCurrentMaterial(value);
                    }}
                    getOptionLabel={(item) => {
                      return item;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option === value;
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={"Material sobrante"}
                          margin="normal"
                          variant="outlined"
                          error={!!errors.material}
                          helperText={errors.material?.message?.toString()}
                          required
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              fullWidth
              id="total"
              label={`Sobrante por material (${currentMaterial})`}
              name="total"
              value={(
                (recoveryData?.totalMaterial ?? 0) - (watch("delivered") ?? 0)
              ).toFixed(2)}
              className="readonly"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("delivered", {
                required: "Peso entregado es obligatorio",
              })}
              error={!!errors?.delivered}
              helperText={errors.delivered?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="delivered"
              label="Peso entregado"
              name="delivered"
              type="number"
              defaultValue={0}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("recovered", {
                required: "Peso regresado es obligatorio",
              })}
              error={!!errors?.delivered}
              helperText={errors.delivered?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="recovered"
              label="Peso regresado"
              name="recovered"
              type="number"
              defaultValue={0}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              fullWidth
              id="difference"
              label={`Diferencia`}
              name="difference"
              value={(
                (watch("delivered") ?? 0) - (watch("recovered") ?? 0)
              ).toFixed(2)}
              className="readonly"
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
