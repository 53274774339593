import React from "react";
import { Button, DialogActions, Grid, TextField } from "@pankod/refine-mui";
import { Box, Dialog, DialogContent, DialogTitle } from "@pankod/refine-mui";
import { IOrderOpenModalProps } from "./interfaces";
import { useUpdate } from "@pankod/refine-core";

export const OrderOpenModal: React.FC<IOrderOpenModalProps> = ({
  visible,
  close,
  title,
  data,
}) => {
  const [comments, setComments] = React.useState(data?.comments ?? "");
  const { mutate } = useUpdate();

  const onSubmit = () => {
    mutate(
      {
        resource: "orders",
        values: {
          status: "Orden",
          comments: comments,
        },
        id: data?.order as number,
      },
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  return (
    <Dialog open={visible} onClose={close} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              ¿Desea abrir la orden?
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(e) => setComments(e.target.value)}
                margin="normal"
                fullWidth
                id="comments"
                label="Comentarios"
                name="comments"
                value={comments}
                rows={5}
                multiline
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cerrar</Button>
        <Button onClick={onSubmit} variant="contained" color="info">
          Abrir orden
        </Button>
      </DialogActions>
    </Dialog>
  );
};
