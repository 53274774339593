import axios from "axios";
import { API_URL, TOKEN_KEY } from "constants";
import { format } from 'date-fns';

const token = localStorage.getItem(TOKEN_KEY);


export const downloadFile = (file, path) => {
  axios({
    url: API_URL + file,
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", path);
    document.body.appendChild(link);
    link.click();
  });
};

export const getImage = (image, format = "thumbnail") => {
  const formats = image.formats;
  if (formats.hasOwnProperty("thumbnail") && format === "thumbnail") {
    return formats.thumbnail.url;
  } else if (formats.hasOwnProperty("small") && format === "small") {
    return formats.small.url;
  } else if (formats.hasOwnProperty("medium") && format === "medium") {
    return formats.medium.url;
  } else if (formats.hasOwnProperty("large") && format === "large") {
    return formats.large.url;
  }
  return image.url;
};

export const getFileExtension = (filename) => {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const validateExtension = (filename, type = "image") => {
  const extension = getFileExtension(filename);
  if (type === "image") {
    return {
      isValid:
        extension === "jpg" || extension === "jpeg" || extension === "png",
      message: "Solo se permiten archivos jpg, jpeg y png",
    };
  } else if (type === "pdf") {
    return {
      isValid: extension === "pdf",
      message: "Solo se permiten archivos pdf",
    };
  }
  return {
    isValid: false,
    message: "No se pudo validar la extensión del archivo",
  };
};

export const sumTwoNumbers = (fisrt, second) => {
  return +fisrt + +second;
};

export const uploadFiles = async (files) => {
  const formData = new FormData();
  const ids = [];
  let uploadFiles = false;

  files.forEach((file) => {
    if (file instanceof File) {
      uploadFiles = true;
      formData.append("files", file);
    } else {
      ids.push(file.id);
    }
  });

  if (uploadFiles) {
    const res = await axios.post(`${API_URL}/api/upload`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    res?.data.forEach((item) => {
      ids.push(item.id);
    });
  }
  if (ids.length === 0) return null;
  return ids;
};

export const getTodayDate = () => {
  const today = format(new Date(), 'yyyy-MM-dd', { timeZone: 'America/Mexico_City' });
  return today;
}