import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SaveButton,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import {
  Controller,
  UseModalFormReturnType,
} from "@pankod/refine-react-hook-form";

import { IProduct, ISupplier } from "interfaces";

export const ProductInModal: React.FC<UseModalFormReturnType> = ({
  saveButtonProps,
  modal: { visible, close, title },
  register,
  control,
  setValue,
  formState: { errors },
}) => {
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const { autocompleteProps: productsAutocompleteProps } =
    useAutocomplete<IProduct>({
      resource: "products",
    });

  const { autocompleteProps: supplierAutocompleteProps } =
    useAutocomplete<ISupplier>({
      resource: "suppliers",
    });

  const closeModal = () => {
    close();
    setValue("supplier", null);
    setValue("product", null);
    setValue("qty", 0);
    setValue("unit_cost", 0);
    setCurrentProduct(null);
  };

  return (
    <Dialog
      open={visible}
      onClose={closeModal}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            control={control}
            name="supplier"
            rules={{ required: "El campo proveedor es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...supplierAutocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    return (
                      supplierAutocompleteProps?.options?.find(
                        (p) => p?.id?.toString() === item?.id?.toString()
                      )?.name ?? ""
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Proveedor"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.supplier}
                      helperText={errors.supplier?.message?.toString()}
                      required
                    />
                  )}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="product"
            rules={{ required: "El campo tipo de producto es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...productsAutocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                    setCurrentProduct(value);
                  }}
                  getOptionLabel={(item) => {
                    const label = productsAutocompleteProps?.options?.find(
                      (p) => p?.id?.toString() === item?.id?.toString()
                    );

                    if (!label?.name || !label?.code) return "";
                    return `${label?.code} - ${label?.name}`;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de producto"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.product}
                      helperText={errors.product?.message?.toString()}
                      autoFocus
                      required
                    />
                  )}
                />
              );
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Medida"
            value={currentProduct?.measure ?? ""}
          />
          <TextField
            {...register("qty", {
              required: "El campo cantidad es obligatorio.",
            })}
            error={!!errors?.qty}
            helperText={errors.qty?.message?.toString()}
            margin="normal"
            fullWidth
            id="qty"
            label="Cantidad"
            name="qty"
            required
          />
          <TextField
            margin="normal"
            fullWidth
            label="Existencia"
            value={currentProduct?.qty ?? ""}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Stock mínimo"
            value={currentProduct?.minimum_stock ?? ""}
          />
          <TextField
            {...register("unit_cost", {
              required: "El campo costo unitario es obligatorio.",
            })}
            error={!!errors?.unit_cost}
            helperText={errors.unit_cost?.message?.toString()}
            margin="normal"
            fullWidth
            id="unit_cost"
            label="Costo unitario"
            name="unit_cost"
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <SaveButton {...saveButtonProps} />
      </DialogActions>
    </Dialog>
  );
};
