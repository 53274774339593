import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { LightboxCustomProps } from "./index.d";
import { API_URL } from "../../constants";

export const LightboxCustom: React.FC<LightboxCustomProps> = ({
  open,
  onClose,
  files,
}) => {
  const slides = (files || [])
    .filter((file) => {
      return file.ext !== ".pdf";
    })
    .map((file) => {
      return { src: API_URL + file.url };
    });
  return <Lightbox open={open} close={onClose} slides={slides} />;
};
