import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, administrator, clients, (list)|(create)|(edit)|(delete)
p, reader, clients, (list)
p, administrator, users, (list)|(create)|(edit)|(delete)
p, administrator, empleados, (list)|(create)|(edit)|(delete)
p, administrator, employees, (list)|(create)|(edit)|(delete)
p, administrator, absences, (list)|(create)|(edit)|(delete)
p, administrator, incapacities, (list)|(create)|(edit)|(delete)
p, administrator, suppliers, (list)|(create)|(edit)|(delete)
p, administrator, productos, (list)|(create)|(edit)|(delete)
p, administrator, products, (list)|(create)|(edit)|(delete)
p, administrator, products-data, (list)|(create)|(edit)|(delete)
p, administrator, materia_prima, (list)|(create)|(edit)|(delete)
p, administrator, raw-materials, (list)|(create)|(edit)|(delete)
p, administrator, raw-materials-data, (list)|(create)|(edit)|(delete)
p, administrator, waxes, (list)|(create)|(edit)|(delete)
p, administrator, excesses, (list)|(create)|(edit)|(delete)
p, administrator, recovery-details, (list)|(create)|(edit)|(delete)
p, administrator, emptyings, (list)|(create)|(edit)|(delete)
p, administrator, orders, (list)|(create)|(edit)|(delete)
p, administrator, new_orders, (list)|(create)|(edit)|(delete)
p, administrator, open, (list)|(create)|(edit)|(delete)
p, administrator, preenabled, (list)|(create)|(edit)|(delete)
p, administrator, enabled, (list)|(create)|(edit)|(delete)
p, administrator, enabled-products, (list)|(create)|(edit)|(delete)
p, administrator, enabled-materials, (list)|(create)|(edit)|(delete)
p, administrator, pasted, (list)|(create)|(edit)|(delete)
p, administrator, fretwork, (list)|(create)|(edit)|(delete)
p, administrator, prewelded, (list)|(create)|(edit)|(delete)
p, administrator, welded, (list)|(create)|(edit)|(delete)
p, administrator, polishes, (list)|(create)|(edit)|(delete)
p, administrator, engraves, (list)|(create)|(edit)|(delete)
p, administrator, details, (list)|(create)|(edit)|(delete)
p, administrator, assembles, (list)|(create)|(edit)|(delete)
p, administrator, lusters, (list)|(create)|(edit)|(delete)
p, administrator, painteds, (list)|(create)|(edit)|(delete)
p, administrator, wrappeds, (list)|(create)|(edit)|(delete)
p, administrator, sents, (list)|(create)|(edit)|(delete)
p, administrator, welded-products, (list)|(create)|(edit)|(delete)
p, administrator, welded-materials, (list)|(create)|(edit)|(delete)
p, administrator, orders_finished, (list)|(create)|(edit)|(delete)
p, administrator, catalogs, (list)|(create)|(edit)|(delete)
p, administrator, invoices, (list)|(create)|(edit)|(delete)
p, administrator, finished_pieces, (list)
`);
