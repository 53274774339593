import React from "react";
import {
  Autocomplete,
  Button,
  DataGrid,
  DialogActions,
  esES,
  Grid,
  List,
  SaveButton,
  TextField,
} from "@pankod/refine-mui";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useAutocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IMaterial } from "interfaces";
import { IClientMaterialsModalProps } from "./interfaces";
import { useCreate, useList } from "@pankod/refine-core";
import { v4 as uuid } from "uuid";
import _ from "lodash-es";

export const ClientProductsModal: React.FC<IClientMaterialsModalProps> = ({
  visible,
  close,
  title,
  data,
  history,
}) => {
  const {
    saveButtonProps,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    refineCoreProps: {
      resource: "null",
    },
    defaultValues: {
      product: null,
      qty: "0",
    },
  });

  const { data: { data: historyData } = { data: [] }, isLoading } =
    useList<any>({
      resource: history?.resource || "products-data",
      queryOptions: {
        enabled: visible && !!history,
      },
      config: history?.config,
      metaData: history?.metaData,
    });

  const { autocompleteProps: materialAutocompleteProps } =
    useAutocomplete<IMaterial>({
      resource: "products",
      onSearch: (searchText) => [
        {
          operator: "or",
          value: [
            {
              field: "code",
              operator: "contains",
              value: searchText,
            },
            {
              field: "name",
              operator: "contains",
              value: searchText,
            },
          ],
        },
      ],
    });

  const cleanInputs = () => {
    reset();
  };

  const { mutate } = useCreate();

  const onSubmit = (submitData: any) => {
    console.log("submitData", submitData);
    console.log("data", data);
    let newData = {
      ...submitData,
      type: "Entrada",
      order: data?.order,
    };
    // setData(newData);
    mutate({
      resource: "products-data",
      values: newData,
    });
    cleanInputs();
  };

  // useEffect(() => {
  //   if (visible) {
  //     // setValue("employee", data?.currentEmployee);
  //     setValue("qty", "0");
  //     setValue("weight", "0");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, visible]);

  const extraData = (history?.extraData || []).map((item: any) => {
    return {
      id: uuid(),
      date: item.date,
      employee: item.employee,
      process: item.process,
      qty: +item.qty,
    };
  });

  const historyWithStatus = [...historyData, ...extraData]?.map((e) => ({
    ...e,
    status: !_.includes(e?.id?.toString(), "-"),
  }));

  return (
    <Dialog open={visible} onClose={close} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                name="product"
                rules={{
                  required: "El campo tipo de producto es obligatorio.",
                }}
                defaultValue={null as any}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...materialAutocompleteProps}
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        // setCurrentProduct(value);
                      }}
                      getOptionLabel={(item) => {
                        const label = materialAutocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        );

                        if (!label?.name || !label?.code) return "";
                        return `${label?.code} - ${label?.name}`;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return (
                          value === undefined ||
                          option.id.toString() === value.id.toString()
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo de producto"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.product}
                          helperText={errors.product?.message?.toString()}
                          autoFocus
                          required
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("qty", {
                  required: "El campo cantidad es obligatorio.",
                })}
                error={!!errors?.qty}
                helperText={errors.qty?.message?.toString()}
                margin="normal"
                fullWidth
                id="qty"
                label="Cantidad"
                name="qty"
                type={"number"}
                required
                defaultValue={""}
              />
            </Grid>
            <Grid container justifyContent="flex-end" marginTop={2}>
              <SaveButton
                {...saveButtonProps}
                onClick={(e: React.BaseSyntheticEvent) => {
                  e.preventDefault();
                  handleSubmit(
                    (data) => onSubmit(data),
                    () => false
                  )(e);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <List breadcrumb={false} title={"Historial"} canCreate={false}>
                <DataGrid
                  rows={historyWithStatus}
                  autoHeight
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  pageSize={10}
                  columns={history?.columns || []}
                  loading={isLoading}
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      textOverflow: "clip",
                      whiteSpace: "break-spaces",
                      lineHeight: 1,
                    },
                  }}
                />
              </List>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
