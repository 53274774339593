import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  useAutocomplete,
  List,
  DataGrid,
  esES,
  Button,
  Edit,
  RefreshButton,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  IEmployee,
  IEnabledMaterial,
  IEnabledProduct,
  IFinishedPieces,
  IExcess,
} from "interfaces";
import { Add, AddOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import {
  excessColumns,
  finishedPiecesColumns,
  materialColumns,
  productColumns,
} from "./grid_columns";
import { EnabledProductModal } from "./modals/products";
import { EnabledMaterialModal } from "./modals/materials";
import { ExcessModal } from "./modals/excess";
import { useModal, useNavigation, useTranslate } from "@pankod/refine-core";
import { v4 as uuid } from "uuid";
import _ from "lodash-es";
// import { FinishedPiecesModal } from "./modals/finished_pieces";
import { useEnabledData } from "./hooks/useEnabledData";
import { FinishedPiecesModal } from "components/Modals/finished_pieces";

export const OrderEnabledEdit: React.FC = () => {
  const params = useParams();
  const { id } = params as any;
  const [pth, setPth] = React.useState(0);
  const [decrease, setDecrease] = React.useState(0);
  const [totalPiece, setTotalPiece] = React.useState(0);
  const [finishedPiece, setFinishedPiece] = React.useState<number>();
  const [realWeight, setRealWeight] = React.useState<number>();

  const [products, setProducts] = React.useState<IEnabledProduct[]>();
  const [currentProduct, setCurrentProduct] = React.useState<IEnabledProduct>();
  const [productsDeleted, setProductsDeleted] = React.useState<any[]>([]);
  const [productsAdd, setProductsAdd] = React.useState(false);

  const [materials, setMaterials] = React.useState<IEnabledMaterial[]>();
  const [currentMaterial, setCurrentMaterial] =
    React.useState<IEnabledMaterial>();
  const [materialsDeleted, setMaterialsDeleted] = React.useState<any[]>([]);
  const [materialsAdd, setMaterialsAdd] = React.useState(false);

  const [excess, setExcess] = React.useState<IExcess[]>();
  const [currentExcess, setCurrentExcess] = React.useState<any>();
  const [excessDeleted, setExcessDeleted] = React.useState<any[]>([]);
  const [excessAdd, setExcessAdd] = React.useState(false);

  const [finishedPieces, setFinishedPieces] = React.useState<IFinishedPieces[]>(
    []
  );

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish, queryResult },
    saveButtonProps,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    refineCoreProps: {
      resource: "enableds/edit",
      redirect: false,
      id: id,
      metaData: {
        populate: ["employee", "order"],
      },
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("enableds.edit.success"),
          type: "success",
        };
      },
    },
  });

  const data = queryResult?.data?.data;

  const {
    products: productsData,
    materials: materialsData,
    excess: excessData,
    isLoading,
    isFetched,
  } = useEnabledData({
    id: id,
    orderId: data?.order?.id,
  });

  useEffect(() => {
    if (isFetched) {
      setProducts(productsData);
      setMaterials(materialsData);
      setExcess(excessData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  useEffect(() => {
    if (
      !isLoading &&
      products &&
      materials &&
      excess &&
      realWeight !== undefined
    ) {
      getPth(products, materials, excess, realWeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, products, materials, excess, realWeight]);

  const onSubmit = (newData: any) => {
    onFinish({
      ...newData,
      order: data?.order?.id,
      pth: pth,
      finishedPiece: finishedPiece,
      realWeight: realWeight,
      decrease: decrease,
      products: products,
      materials: materials,
      excess: excess,
      finishedPieces: finishedPieces,
      products_deleted: productsDeleted,
      materials_deleted: materialsDeleted,
      excess_deleted: excessDeleted,
    });
  };

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  const {
    show: showProductModal,
    close: closeProductModal,
    visible: visibleProductModal,
  } = useModal();

  const {
    show: showMaterialModal,
    close: closeMaterialModal,
    visible: visibleMaterialModal,
  } = useModal();

  const {
    show: showExcessModal,
    close: closeExcessModal,
    visible: visibleExcessModal,
  } = useModal();

  const {
    show: showFinishedPiecesModal,
    close: closeFinishedPiecesModal,
    visible: visibleFinishedPiecesModal,
  } = useModal();

  const handleAddProduct = (
    data: IEnabledProduct,
    another: boolean = false
  ) => {
    if (!another) {
      closeProductModal();
    }

    const currentProducts = products || [];

    if (data?.action === "create") {
      const productId = uuid();
      const newProduct = {
        id: productId,
        product: data.product,
        quantity_pieces: data.quantity_pieces,
        weight_pieces: data.weight_pieces,
        delete: handleDeleteProduct,
        update: handleUpdateProduct,
        action: data?.action,
      };
      setProducts([...currentProducts, newProduct]);
    } else if (data?.action === "update") {
      const newProducts = currentProducts.map((p) => {
        if (p.id === data.id) {
          return {
            ...p,
            product: data.product,
            quantity_pieces: data.quantity_pieces,
            weight_pieces: data.weight_pieces,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return p;
      });
      setProducts(newProducts);
      setCurrentProduct(undefined);
    }
  };

  const handleDeleteProduct = (id: string) => {
    const currentProducts = products || [];
    const newProducts = currentProducts.filter((p) => p.id !== id);
    setProducts(newProducts);
    if (!_.includes(id, "-")) {
      setProductsDeleted([...productsDeleted, id]);
    }
  };

  const handleUpdateProduct = (data: IEnabledProduct, canAdd: boolean) => {
    setCurrentProduct(data);
    setProductsAdd(canAdd);
    showProductModal();
  };

  const handleAddMaterial = (
    data: IEnabledMaterial,
    another: boolean = false
  ) => {
    if (!another) {
      closeMaterialModal();
    }

    const currentMaterials = materials || [];

    if (data?.action === "create") {
      const materialId = uuid();
      const newMaterial = {
        id: materialId,
        material: data.material,
        delivered_weight: data.delivered_weight,
        delete: handleDeleteMaterial,
        update: handleUpdateMaterial,
        action: "create",
      };
      setMaterials([...currentMaterials, newMaterial]);
    } else if (data?.action === "update") {
      const newMaterials = currentMaterials.map((m) => {
        if (m.id === data.id) {
          return {
            ...m,
            material: data.material,
            delivered_weight: data.delivered_weight,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return m;
      });
      setMaterials(newMaterials);
      setCurrentMaterial(undefined);
    }
  };

  const handleDeleteMaterial = (id: string) => {
    const currentMaterials = materials || [];
    const newMaterials = currentMaterials.filter((m) => m.id !== id);
    setMaterials(newMaterials);
    if (!_.includes(id, "-")) {
      setMaterialsDeleted([...materialsDeleted, id]);
    }
  };

  const handleUpdateMaterial = (data: IEnabledMaterial, canAdd: boolean) => {
    setCurrentMaterial(data);
    setMaterialsAdd(canAdd);
    showMaterialModal();
  };

  const handleAddExcess = (data: IExcess, another: boolean = false) => {
    if (!another) {
      closeExcessModal();
    }

    const currentExcess = excess || [];

    if (data?.action === "create") {
      const excessId = uuid();
      const newExcess = {
        id: excessId,
        employee: data.employee,
        material: _.get(data.material, "id", undefined),
        weight: data.weight,
        delete: handleDeleteExcess,
        update: handleUpdateExcess,
        action: "create",
      };
      setExcess([...currentExcess, newExcess]);
    } else if (data?.action === "update") {
      const newExcess = currentExcess.map((e) => {
        if (e.id === data.id) {
          return {
            ...e,
            employee: data.employee,
            material: _.get(data.material, "id", undefined),
            weight: data.weight,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return e;
      });
      setExcess(newExcess);
      setCurrentExcess(undefined);
    }
  };

  const handleDeleteExcess = (id: string) => {
    const currentExcess = excess || [];
    const newExcess = currentExcess.filter((m) => m.id !== id);
    setExcess(newExcess);
    if (!_.includes(id, "-")) {
      setExcessDeleted([...excessDeleted, id]);
    }
  };

  const handleUpdateExcess = (data: IExcess, canAdd: boolean) => {
    setCurrentExcess(data);
    setExcessAdd(canAdd);
    showExcessModal();
  };

  const handleAddFinishedPieces = (newData: any) => {
    const newWeight = newData?.total_real_weight;
    const newFinishedPiece = newData?.total_qty;

    setRealWeight(newWeight);
    setFinishedPiece(newFinishedPiece);

    setFinishedPieces([
      ...finishedPieces,
      {
        ...newData,
        process: "Habilitado",
        order: data?.order?.id,
      },
    ]);
  };

  const getPth = React.useCallback(
    (
      products: IEnabledProduct[],
      materials: IEnabledMaterial[],
      excess: IExcess[],
      realWeight: number
    ) => {
      const totalProducts = products.reduce(
        (acc, item) => +acc + +item.weight_pieces,
        0
      );

      const totalMaterials = materials.reduce(
        (acc, item) => +acc + +item.delivered_weight,
        0
      );

      const totalExcess = excess.reduce((acc, item) => +acc + +item.weight, 0);

      const pthTotal = totalProducts + totalMaterials - totalExcess;

      const decreaseTotal = pthTotal - realWeight;

      setPth(pthTotal);
      setDecrease(decreaseTotal);
    },
    []
  );

  useEffect(() => {
    setTotalPiece(data?.order?.total_pieces);
    setFinishedPiece(data?.finished_pieces);
    setRealWeight(data?.real_weight);
  }, [data?.finished_pieces, data?.order?.total_pieces, data?.real_weight]);

  const productsWithActions = products?.map((p) => ({
    ...p,
    delete: handleDeleteProduct,
    update: handleUpdateProduct,
    action: "none",
  }));

  const materialsWithActions = materials?.map((m) => ({
    ...m,
    delete: handleDeleteMaterial,
    update: handleUpdateMaterial,
    action: "none",
  }));

  const excessWithActions = excess?.map((e) => ({
    ...e,
    delete: handleDeleteExcess,
    update: handleUpdateExcess,
    action: "none",
  }));

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: isLoading || formLoading,
        onClick: handleSubmit(onSubmit),
      }}
      resource="enableds"
      headerButtons={() => {
        return (
          <RefreshButton
            resourceNameOrRouteName="enableds"
            recordItemId={id}
            metaData={{ populate: ["employee"] }}
          />
        );
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empleado"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.employee}
                        helperText={errors.employee?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Peso Piezas Terminadas (PTH) "
              // defaultValue={0}
              value={pth.toFixed(2)}
              focused={false}
              className={"readonly"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="finished_pieces"
                  label="Pz. Totales"
                  name="finished_pieces"
                  // defaultValue={" "}
                  value={totalPiece || 0}
                  type="number"
                  className="readonly"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="finished_pieces"
                  label="Pz. Terminadas"
                  name="finished_pieces"
                  // defaultValue={0}
                  value={finishedPiece || 0}
                  type="number"
                  className="readonly"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="real_weight"
                  label="PRH"
                  name="real_weight"
                  // defaultValue={0}
                  value={realWeight || 0}
                  className="readonly"
                />
              </Grid>
              <Grid
                item
                xs={3}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Button
                  color={"info"}
                  size={"small"}
                  onClick={() => showFinishedPiecesModal()}
                >
                  <AddOutlined /> Agregar Piezas
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Merma"
              // defaultValue={0}
              value={decrease.toFixed(2)}
              focused={false}
              className={"readonly"}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Productos"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showProductModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
            >
              <DataGrid
                rows={productsWithActions || []}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={productColumns}
                loading={isLoading}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
          </Grid>
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Materiales"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showMaterialModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
            >
              <DataGrid
                rows={materialsWithActions || []}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={materialColumns}
                loading={isLoading}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
          </Grid>
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Sobrantes"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showExcessModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
            >
              <DataGrid
                rows={excessWithActions || []}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={excessColumns}
                loading={isLoading}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
            <EnabledProductModal
              visible={visibleProductModal}
              close={closeProductModal}
              title={"Nuevo producto"}
              setData={handleAddProduct}
              data={currentProduct}
              canAdd={productsAdd}
              setCanAdd={setProductsAdd}
            />
            <EnabledMaterialModal
              visible={visibleMaterialModal}
              close={closeMaterialModal}
              title={"Nuevo material"}
              setData={handleAddMaterial}
              data={currentMaterial}
              canAdd={materialsAdd}
              setCanAdd={setMaterialsAdd}
            />
            <ExcessModal
              visible={visibleExcessModal}
              close={closeExcessModal}
              title={"Nuevo sobrante"}
              setData={handleAddExcess}
              data={{
                employee: getValues("employee"),
                ...currentExcess,
              }}
              canAdd={excessAdd}
              setCanAdd={setExcessAdd}
            />

            <FinishedPiecesModal
              visible={visibleFinishedPiecesModal}
              close={closeFinishedPiecesModal}
              title={"Piezas terminadas"}
              data={{
                currentEmployee: getValues("employee"),
                orderQty: totalPiece || 0,
                currentQty: finishedPiece || 0,
                realWeight: realWeight || 0,
              }}
              setData={handleAddFinishedPieces}
              history={{
                config: {
                  filters: [
                    {
                      field: "process",
                      operator: "eq",
                      value: "Habilitado",
                    },
                    {
                      field: "order",
                      operator: "" as any,
                      value: data?.order?.id,
                    },
                  ],
                },
                metaData: {
                  populate: ["employee"],
                },
                columns: finishedPiecesColumns,
                extraData: finishedPieces,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Edit>
  );
};
