import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  useAutocomplete,
  Create,
  Button,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IEmployee, IFinishedPieces, IOrder } from "interfaces";
import { useLocation } from "react-router-dom";
import {
  HttpError,
  useModal,
  useNavigation,
  useOne,
  useTranslate,
} from "@pankod/refine-core";
import { AddOutlined } from "@mui/icons-material";
import { finishedPiecesColumns } from "./grid_columns";
import FileInput from "components/FileInput";
import { uploadFiles } from "functions";
import { FinishedPiecesModal } from "components/Modals/finished_pieces";

type LocationState = {
  recordItemId: string;
};

export const WrappedCreate: React.FC = () => {
  const [totalPiece, setTotalPiece] = React.useState(0);
  const [finishedPiece, setFinishedPiece] = React.useState<number>(0);
  const [realWeight, setRealWeight] = React.useState<number>(0);

  const [finishedPieces, setFinishedPieces] = React.useState<IFinishedPieces[]>(
    []
  );

  const [files, setFiles] = React.useState([]);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      resource: "wrappeds/create",
      redirect: false,
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("wrappeds.create.success"),
          type: "success",
        };
      },
    },
  });

  const location = useLocation();
  const { recordItemId } = location.state as LocationState;

  const { data, isLoading, isFetched } = useOne<IOrder, HttpError>({
    resource: "orders",
    id: recordItemId,
    metaData: {
      populate: ["assemble"],
    },
  });

  const onSubmit = async (data: any) => {
    const ids = await uploadFiles(files);

    onFinish({
      ...data,
      order: recordItemId,
      realWeight: realWeight,
      finishedPiece: finishedPiece,
      finishedPieces: finishedPieces,
      files: ids,
    });
  };

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  const {
    show: showFinishedPiecesModal,
    close: closeFinishedPiecesModal,
    visible: visibleFinishedPiecesModal,
  } = useModal();

  const handleAddFinishedPieces = (newData: any) => {
    const newWeight = newData?.total_real_weight;
    const newFinishedPiece = newData?.total_qty;

    setRealWeight(newWeight);
    setFinishedPiece(newFinishedPiece);

    setFinishedPieces([
      ...finishedPieces,
      {
        ...newData,
        process: "Envuelto",
        order: recordItemId,
      },
    ]);
  };

  useEffect(() => {
    if (!isLoading && isFetched && data?.data?.total_pieces !== undefined) {
      setTotalPiece(data?.data?.total_pieces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, isLoading, isFetched]);

  const remove = (id: number) => {
    const newFiles = files.filter((_: any, index: number) => index !== id);
    setFiles(newFiles);
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empleado"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.employee}
                        helperText={errors.employee?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="finished_pieces"
                  label="Pz. Totales"
                  name="finished_pieces"
                  // defaultValue={" "}
                  value={totalPiece || 0}
                  type="number"
                  className="readonly"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="finished_pieces"
                  label="Pz. Terminadas"
                  name="finished_pieces"
                  // defaultValue={0}
                  value={finishedPiece || 0}
                  type="number"
                  className="readonly"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="real_weight"
                  label="PRE"
                  name="real_weight"
                  // defaultValue={0}
                  value={realWeight || 0}
                  className="readonly"
                />
              </Grid>
              <Grid
                item
                xs={3}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Button
                  color={"info"}
                  size={"small"}
                  onClick={() => showFinishedPiecesModal()}
                >
                  <AddOutlined /> Agregar Piezas
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FileInput
              setValue={setFiles}
              remove={remove}
              acceptedFiles={files}
            />
          </Grid>
          <FinishedPiecesModal
            visible={visibleFinishedPiecesModal}
            close={closeFinishedPiecesModal}
            title={"Piezas terminadas"}
            data={{
              currentEmployee: getValues("employee"),
              orderQty: totalPiece || 0,
              currentQty: finishedPiece || 0,
              realWeight: realWeight,
            }}
            setData={handleAddFinishedPieces}
            history={{
              config: {
                filters: [
                  {
                    field: "process",
                    operator: "eq",
                    value: "Envuelto",
                  },
                  {
                    field: "order",
                    operator: "" as any,
                    value: recordItemId,
                  },
                ],
              },
              metaData: {
                populate: ["employee"],
              },
              columns: finishedPiecesColumns,
              extraData: finishedPieces,
            }}
          />
        </Grid>
      </Box>
    </Create>
  );
};
