import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  Layout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";

import "./styles.scss";

import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "custom/strapi-v4/dataProvider";
import { useTranslation } from "react-i18next";
import { ColorModeContextProvider } from "contexts";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
import { ClientCreate, ClientEdit, ClientList } from "pages/clients";
import { UserCreate, UserEdit, UserList } from "pages/users";
import { LoginPage } from "pages/login";
import { newEnforcer } from "casbin";
import { adapter, model } from "accessControl";
import { EmployeeCreate, EmployeeEdit, EmployeeList } from "pages/employees";
import { AbsenceCreate, AbsenceEdit, AbsenceList } from "pages/absence";
import {
  IncapacityCreate,
  IncapacityEdit,
  IncapacityList,
} from "pages/incapacity";
import { SupplierCreate, SupplierEdit, SupplierList } from "pages/supplier";
import {
  ProductCreate,
  ProductDataList,
  ProductEdit,
  ProductList,
} from "pages/product";
import {
  MaterialCreate,
  MaterialDataList,
  MaterialEdit,
  MaterialList,
} from "pages/material";
import { WaxCreate, WaxEdit, WaxList } from "pages/wax";
import {
  OrderPreEnabledCreate,
  OrderFinishedList,
  OrderOpenList,
  OrderEnabledEdit,
  OrderCreate,
  PastedCreate,
  PastedEdit,
  FretworkCreate,
  FretworkEdit,
  PreWeldedCreate,
  WeldedEdit,
  PolishedCreate,
  PolishedEdit,
  EngravedCreate,
  EngravedEdit,
  DetailedCreate,
  DetailedEdit,
  AssembledCreate,
  AssembledEdit,
  LusterCreate,
  LusterEdit,
  PaintedCreate,
  PaintedEdit,
  WrappedCreate,
  WrappedEdit,
  SentCreate,
  SentEdit,
  OrderEdit,
} from "pages/order";
import { ExcessList } from "pages/excess";
import { CatalogCreate, CatalogEdit, CatalogList } from "pages/catalogs";
import { InvoiceCreate, InvoiceEdit, InvoiceList } from "pages/invoices";
import { FinishedPiecesList } from "pages/finishedPieces";
import { RecoveryCreate, RecoveryEdit, RecoveryList } from "pages/recovery";
import { EmptyingCreate, EmptyingEdit, EmptyingList } from "pages/emptying";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { Link } = routerProvider;

  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          notificationProvider={notificationProvider}
          Layout={Layout}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          routerProvider={{ ...routerProvider }}
          authProvider={authProvider}
          dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
          i18nProvider={i18nProvider}
          LoginPage={LoginPage}
          accessControlProvider={{
            can: async ({ resource, action }) => {
              const enforcer = await newEnforcer(model, adapter);
              const userRole = await authProvider.getUserIdentity?.();
              //console.log("resource", resource, "action", action);
              const can = await enforcer.enforce(
                userRole.type,
                resource,
                action
              );

              return Promise.resolve({ can });
            },
          }}
          Title={({ collapsed }) => (
            <Link to="/">
              {collapsed ? (
                <img
                  src="/logo_small.png"
                  alt="Refine"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // padding: "12px 24px",
                  }}
                />
              ) : (
                <img
                  src="/logo_small.png"
                  alt="Refine"
                  style={{
                    width: "200px",
                    // padding: "12px 24px",
                  }}
                />
              )}
            </Link>
          )}
          resources={[
            {
              name: "orders",
              options: { label: "Ordenes" },
            },
            {
              name: "open",
              parentName: "orders",
              options: { label: "Activas" },
              list: OrderOpenList,
              create: OrderCreate,
              edit: OrderEdit,
            },
            {
              name: "preenabled",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: OrderPreEnabledCreate,
            },
            {
              name: "enabled",
              parentName: "orders",
              options: {
                hide: true,
              },
              edit: OrderEnabledEdit,
            },
            {
              name: "pasted",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: PastedCreate,
              edit: PastedEdit,
            },
            {
              name: "fretwork",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: FretworkCreate,
              edit: FretworkEdit,
            },
            {
              name: "prewelded",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: PreWeldedCreate,
            },
            {
              name: "welded",
              parentName: "orders",
              options: {
                hide: true,
              },
              edit: WeldedEdit,
            },
            {
              name: "polishes",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: PolishedCreate,
              edit: PolishedEdit,
            },
            {
              name: "engraves",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: EngravedCreate,
              edit: EngravedEdit,
            },
            {
              name: "details",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: DetailedCreate,
              edit: DetailedEdit,
            },
            {
              name: "assembles",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: AssembledCreate,
              edit: AssembledEdit,
            },
            {
              name: "lusters",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: LusterCreate,
              edit: LusterEdit,
            },
            {
              name: "painteds",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: PaintedCreate,
              edit: PaintedEdit,
            },
            {
              name: "wrappeds",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: WrappedCreate,
              edit: WrappedEdit,
            },
            {
              name: "sents",
              parentName: "orders",
              options: {
                hide: true,
              },
              create: SentCreate,
              edit: SentEdit,
            },
            {
              name: "orders_finished",
              parentName: "orders",
              options: { label: "Terminadas", route: "orders/finished" },
              list: OrderFinishedList,
              // create: WaxCreate,
              // edit: WaxEdit,
            },
            {
              name: "new_orders",
              parentName: "orders",
              options: { label: "Nueva" },
              create: OrderCreate,
            },
            {
              name: "invoices",
              options: { label: "Facturas" },
              list: InvoiceList,
              create: InvoiceCreate,
              edit: InvoiceEdit,
              canDelete: true,
            },
            {
              name: "clients",
              list: ClientList,
              create: ClientCreate,
              edit: ClientEdit,
              canDelete: true,
            },
            {
              name: "suppliers",
              list: SupplierList,
              create: SupplierCreate,
              edit: SupplierEdit,
              canDelete: true,
            },
            {
              name: "productos",
            },
            {
              name: "products",
              options: { label: "Listado" },
              parentName: "productos",
              list: ProductList,
              create: ProductCreate,
              edit: ProductEdit,
              canDelete: true,
            },
            {
              name: "products-data",
              options: { label: "Inventario" },
              parentName: "productos",
              list: ProductDataList,
            },
            {
              name: "materia_prima",
            },
            {
              name: "raw-materials",
              options: { label: "Listado" },
              parentName: "materia_prima",
              list: MaterialList,
              create: MaterialCreate,
              edit: MaterialEdit,
              canDelete: true,
            },
            {
              name: "raw-materials-data",
              options: { label: "Inventario" },
              parentName: "materia_prima",
              list: MaterialDataList,
            },
            {
              name: "waxes",
              list: WaxList,
              create: WaxCreate,
              edit: WaxEdit,
              canDelete: true,
            },
            {
              name: "excesses",
              list: ExcessList,
            },
            {
              name: "recovery-details",
              list: RecoveryList,
              create: RecoveryCreate,
              edit: RecoveryEdit,
            },
            {
              name: "emptyings",
              list: EmptyingList,
              create: EmptyingCreate,
              edit: EmptyingEdit,
            },
            {
              name: "empleados",
            },
            {
              name: "employees",
              parentName: "empleados",
              options: { label: "Listado" },
              list: EmployeeList,
              create: EmployeeCreate,
              edit: EmployeeEdit,
              canDelete: true,
            },
            {
              name: "absences",
              parentName: "empleados",
              options: { label: "Faltas" },
              list: AbsenceList,
              create: AbsenceCreate,
              edit: AbsenceEdit,
              canDelete: true,
            },
            {
              name: "incapacities",
              parentName: "empleados",
              options: { label: "Incapacidades" },
              list: IncapacityList,
              create: IncapacityCreate,
              edit: IncapacityEdit,
              canDelete: true,
            },
            {
              name: "catalogs",
              list: CatalogList,
              create: CatalogCreate,
              edit: CatalogEdit,
              canDelete: true,
            },
            {
              name: "users",
              list: UserList,
              create: UserCreate,
              edit: UserEdit,
              canDelete: true,
            },
            {
              name: "finished_pieces",
              options: { label: "Piezas trabajadas" },
              list: FinishedPiecesList,
              // create: WaxCreate,
              // edit: WaxEdit,
            },
          ]}
        />
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
