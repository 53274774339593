import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  DeleteButton,
  List,
  Stack,
  esES,
  BooleanField,
  DateField,
  Avatar,
  Button,
} from "@pankod/refine-mui";
import { IAbsence, IEmployee } from "interfaces";
import { Check, Close, PictureAsPdf } from "@mui/icons-material";
import ImageViewer from "react-simple-image-viewer";
import "dayjs/locale/es-mx";
import { downloadFile, getImage } from "functions";
import { API_URL } from "../../constants";

import { ListAltOutlined, VaccinesOutlined } from "@mui/icons-material";
import { useModalForm } from "@pankod/refine-react-hook-form";
import { CreateAbsenceModal } from "components/CreateAbsenceModal";
import { CreateIncapacityModal } from "components/CreateIncapacityModal";

export const EmployeeList: React.FC = () => {
  const defaultPreview = {
    images: [],
    index: 0,
    isOpen: false,
  };
  const [previewImage, setPreviewImage] = React.useState<any>(defaultPreview);

  const { dataGridProps } = useDataGrid<IEmployee>({
    metaData: {
      populate: [
        "job_application",
        "contract",
        "regulation",
        "proof_of_address",
        "photo",
      ],
    },
  });

  const createAbsenceModalFormProps = useModalForm<IAbsence>({
    refineCoreProps: {
      action: "create",
      resource: "absences",
      redirect: false,
    },
  });

  const {
    modal: { show: showCreateAbsenceModal },
  } = createAbsenceModalFormProps;

  const createIncapacityModalFormProps = useModalForm<IAbsence>({
    refineCoreProps: {
      action: "create",
      resource: "incapacities",
      redirect: false,
    },
  });

  const {
    modal: { show: showCreateIncapacityModal },
  } = createIncapacityModalFormProps;

  const columns = React.useMemo<GridColumns<IEmployee>>(
    () => [
      { field: "name", headerName: "Nombre", flex: 2, minWidth: 200 },
      { field: "email", headerName: "Correo", flex: 2, minWidth: 200 },
      {
        field: "active",
        headerName: "Activo",
        flex: 1,
        renderCell: function render({ row }) {
          return (
            <BooleanField
              value={row.active}
              trueIcon={<Check />}
              falseIcon={<Close />}
              valueLabelTrue="Activo"
              valueLabelFalse="Inactivo"
            />
          );
        },
        align: "center",
        headerAlign: "center",
        filterable: false,
      },
      {
        field: "job_application",
        headerName: "Solicitud",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.job_application) return <Close />;
          return (
            <PictureAsPdf
              style={{ cursor: "pointer" }}
              onClick={() =>
                downloadFile(row.job_application.url, row.job_application.name)
              }
            />
          );
        },
        align: "center",
        headerAlign: "center",
        filterable: false,
      },
      {
        field: "contract",
        headerName: "Cont.",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.contract) return <Close />;
          return (
            <PictureAsPdf
              style={{ cursor: "pointer" }}
              onClick={() => downloadFile(row.contract.url, row.contract.name)}
            />
          );
        },
        align: "center",
        headerAlign: "center",
        filterable: false,
      },
      {
        field: "regulation",
        headerName: "Reg.",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.regulation) return <Close />;
          return (
            <PictureAsPdf
              style={{ cursor: "pointer" }}
              onClick={() =>
                downloadFile(row.regulation.url, row.regulation.name)
              }
            />
          );
        },
        align: "center",
        headerAlign: "center",
        filterable: false,
      },
      {
        field: "proof_of_address",
        headerName: "C.D.",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.proof_of_address) return <Close />;
          return (
            <PictureAsPdf
              style={{ cursor: "pointer" }}
              onClick={() =>
                downloadFile(
                  row.proof_of_address.url,
                  row.proof_of_address.name
                )
              }
            />
          );
        },
        align: "center",
        headerAlign: "center",
        filterable: false,
      },
      {
        field: "photo",
        headerName: "Foto",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.photo) return <Close />;
          const url = `${API_URL}${getImage(row.photo, "small")}`;
          return (
            <Avatar
              alt="Remy Sharp"
              src={url}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setPreviewImage({
                  images: [API_URL + row.photo.url],
                  index: 0,
                  isOpen: true,
                })
              }
            />
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 100,
        filterable: false,
      },
      {
        field: "date_of_entry",
        headerName: "Ingreso",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.date_of_entry) return "Sin fecha";
          return (
            <DateField
              value={row.date_of_entry}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
        minWidth: 130,
      },
      { field: "phone", headerName: "Tel", flex: 1, minWidth: 150 },
      {
        field: "emergency_phone",
        headerName: "Emergencia",
        flex: 1,
        minWidth: 150,
      },
      { field: "nss", headerName: "NSS", flex: 1, minWidth: 150 },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        filterable: false,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
              <Button
                title="Faltas"
                sx={{ minWidth: 0 }}
                onClick={() => showCreateAbsenceModal(params.row.id)}
              >
                <ListAltOutlined fontSize="small" />
              </Button>
              <Button
                title="Incapacidades"
                sx={{ minWidth: 0 }}
                onClick={() => showCreateIncapacityModal(params.row.id)}
              >
                <VaccinesOutlined fontSize="small" />
              </Button>
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              job_application: false,
              contract: false,
              regulation: false,
              proof_of_address: false,
              photo: false,
            },
          },
        }}
      />
      {previewImage.isOpen && (
        <ImageViewer
          src={previewImage.images}
          currentIndex={previewImage.index}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => {
            setPreviewImage(defaultPreview);
          }}
          backgroundStyle={{
            zIndex: 1000000,
          }}
        />
      )}
      <CreateAbsenceModal {...createAbsenceModalFormProps} />
      <CreateIncapacityModal {...createIncapacityModalFormProps} />
    </List>
  );
};
