import {
  Box,
  TextField,
  Create,
  Grid,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { getTodayDate } from "functions";
import { IEmployee } from "interfaces";

export const AbsenceCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm();

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              render={({ field }) => (
                <Autocomplete
                  {...autocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    console.log("item", item);
                    return (
                      autocompleteProps?.options?.find(
                        (p) => p?.id?.toString() === item?.id?.toString()
                      )?.name ?? ""
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    option.id.toString() === value.toString()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Empleado"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.employee}
                      helperText={errors.employee?.message?.toString()}
                      required
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("start_date", {
                required: "El campo fecha de inicio es obligatorio.",
              })}
              error={!!errors?.start_date}
              helperText={errors.start_date?.message?.toString()}
              margin="normal"
              fullWidth
              type={"date"}
              id="start_date"
              label="Fecha de inicio"
              name="start_date"
              defaultValue={getTodayDate()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("end_date", {
                required: "El campo fecha de termino es obligatorio.",
              })}
              error={!!errors?.end_date}
              helperText={errors.end_date?.message?.toString()}
              margin="normal"
              fullWidth
              type={"date"}
              id="end_date"
              label="Fecha de termino"
              name="end_date"
              defaultValue={getTodayDate()}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
