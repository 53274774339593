import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  DeleteButton,
  List,
  Stack,
  esES,
  Button,
} from "@pankod/refine-mui";
import { IProduct } from "interfaces";
import { ProductInModal } from "components/ProductInModal";
import { ProductOutModal } from "components/ProductOutModal";
import { useModalForm } from "@pankod/refine-react-hook-form";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
} from "@mui/icons-material";

export const ProductList: React.FC = () => {
  const {
    dataGridProps,
    tableQueryResult: { refetch },
  } = useDataGrid<IProduct>();

  const productInModalFormProps = useModalForm<IProduct>({
    refineCoreProps: {
      action: "create",
      resource: "products-data",
      redirect: false,
      onMutationSuccess: () => {
        refetch();
      },
    },
    defaultValues: {
      type: "Entrada",
    },
  });
  const {
    modal: { show: showProductInModal },
  } = productInModalFormProps;

  const productOutModalFormProps = useModalForm<IProduct>({
    refineCoreProps: {
      action: "create",
      resource: "products-data",
      redirect: false,
      onMutationSuccess: () => {
        refetch();
      },
    },
    defaultValues: {
      type: "Salida",
      unit_cost: 0,
    },
  });
  const {
    modal: { show: showProductOutModal },
  } = productOutModalFormProps;

  const columns = React.useMemo<GridColumns<IProduct>>(
    () => [
      { field: "code", headerName: "Código", flex: 1 },
      { field: "name", headerName: "Nombre", flex: 1 },
      { field: "measure", headerName: "Medida", flex: 1 },
      { field: "qty", headerName: "Existencia", flex: 1 },
      { field: "minimum_stock", headerName: "Stock minimo", flex: 1 },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button
            startIcon={<ArrowUpwardOutlined />}
            color="primary"
            variant="contained"
            onClick={() => {
              showProductInModal();
            }}
          >
            Alta Inventario
          </Button>
          <Button
            startIcon={<ArrowDownwardOutlined />}
            color="primary"
            variant="contained"
            onClick={() => {
              showProductOutModal();
            }}
          >
            Baja Inventario
          </Button>
        </>
      )}
    >
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
      <ProductInModal {...productInModalFormProps} />
      <ProductOutModal {...productOutModalFormProps} />
    </List>
  );
};
