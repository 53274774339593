import { GridColumns } from "@pankod/refine-mui";

export const materialColumns: GridColumns = [
  {
    field: "material",
    headerName: "Material",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.material.code + " - " + params.row.material.name;
    },
  },
  {
    field: "delivered_weight",
    headerName: "Peso Entregado",
    flex: 1,
    sortable: false,
    filterable: false,
  },
];
