import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  useAutocomplete,
  List,
  DataGrid,
  esES,
  Button,
  Create,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IEmployee, IPastedMaterial } from "interfaces";
import { Add } from "@mui/icons-material";

import { useModal, useNavigation, useTranslate } from "@pankod/refine-core";
import { v4 as uuid } from "uuid";
import { materialColumns } from "./grid_columns";
import _ from "lodash-es";
import { EnabledMaterialModal } from "./modals/materials";

const excessMaterial = ["Oro", "Plata", "Cobre", "Overlay"];

export const EmptyingCreate: React.FC = () => {
  const [materials, setMaterials] = React.useState<IPastedMaterial[]>([]);
  const [currentMaterial, setCurrentMaterial] =
    React.useState<IPastedMaterial>();
  const [materialsDeleted, setMaterialsDeleted] = React.useState<any[]>([]);
  const [materialsAdd, setMaterialsAdd] = React.useState(false);

  const [excessCurrentMaterial, setExcessCurrentMaterial] = React.useState(
    excessMaterial[0]
  );

  const [decrease, setDecrease] = React.useState(0);
  const [difference, setDifference] = React.useState(0);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      resource: "emptying/create",
      redirect: false,
      onMutationSuccess: () => {
        list("emptyings");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("emptyings.create.success"),
          type: "success",
        };
      },
    },
  });

  // const location = useLocation();
  // const { recordItemId } = location.state as LocationState;

  const onSubmit = (newData: any) => {
    onFinish({
      ...newData,
      decrease: decrease,
      materials: materials,
    });
  };

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  const {
    show: showMaterialModal,
    close: closeMaterialModal,
    visible: visibleMaterialModal,
  } = useModal();

  const handleAddMaterial = (
    data: IPastedMaterial,
    another: boolean = false
  ) => {
    if (!another) {
      closeMaterialModal();
    }
    if (data?.action === "create") {
      const materialId = uuid();
      const newMaterial = {
        id: materialId,
        material: data.material,
        delivered_weight: data.delivered_weight,
        delete: handleDeleteMaterial,
        update: handleUpdateMaterial,
        action: "create",
      };
      setMaterials([...materials, newMaterial]);
    } else if (data?.action === "update") {
      const newMaterials = materials.map((m) => {
        if (m.id === data.id) {
          return {
            ...m,
            material: data.material,
            delivered_weight: data.delivered_weight,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return m;
      });
      setMaterials(newMaterials);
      setCurrentMaterial(undefined);
    }
  };

  const handleDeleteMaterial = (id: string) => {
    const newMaterials = materials.filter((m) => m.id !== id);
    setMaterials(newMaterials);
    if (!_.includes(id, "-")) {
      setMaterialsDeleted([...materialsDeleted, id]);
    }
  };

  const handleUpdateMaterial = (data: IPastedMaterial, canAdd: boolean) => {
    setCurrentMaterial(data);
    setMaterialsAdd(canAdd);
    showMaterialModal();
  };

  const materialsWithActions = materials?.map((m) => ({
    ...m,
    delete: handleDeleteMaterial,
    update: handleUpdateMaterial,
    action: "none",
  }));

  useEffect(() => {
    const sumMaterial = materials.reduce((acc, m) => {
      return acc + +m.delivered_weight;
    }, 0);
    const excessWeight = watch("excess_weight");
    const materialReturned = watch("material_returned");

    const totalDecrease = (
      +sumMaterial +
      +excessWeight -
      +materialReturned
    ).toFixed(2);
    setDecrease(+totalDecrease);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials, watch("excess_weight"), watch("material_returned")]);

  useEffect(() => {
    const quantityPerPipe = watch("quantity_per_pipe");
    const returnedPieces = watch("returned_pieces");

    const totalDifference = (+quantityPerPipe - +returnedPieces).toFixed(2);
    setDifference(+totalDifference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("quantity_per_pipe"), watch("returned_pieces")]);

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: formLoading,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empleado"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.employee}
                        helperText={errors.employee?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={3}>
            <Controller
              control={control}
              name="material"
              rules={{ required: "Material sobrante es obligatoria" }}
              defaultValue={excessCurrentMaterial as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={excessMaterial}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      setExcessCurrentMaterial(value);
                    }}
                    getOptionLabel={(item) => {
                      return item;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option === value;
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={"Material sobrante"}
                          margin="normal"
                          variant="outlined"
                          error={!!errors.material}
                          helperText={errors.material?.message?.toString()}
                          required
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              {...register("excess_weight", {
                required: "Peso sobrante es obligatorio.",
              })}
              error={!!errors?.excess_weight}
              helperText={errors.excess_weight?.message?.toString()}
              margin="normal"
              fullWidth
              label="Peso sobrante"
              type={"number"}
              required
              defaultValue={"0"}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              {...register("material_returned", {
                required: "Material regresado es obligatorio.",
              })}
              error={!!errors?.material_returned}
              helperText={errors.material_returned?.message?.toString()}
              margin="normal"
              fullWidth
              label="Material regresado"
              type={"number"}
              required
              defaultValue={"0"}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              margin="normal"
              fullWidth
              label="Merma"
              className="readonly"
              value={decrease}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              {...register("encapsulated_pipes", {
                required: "Tubos enfrascados es obligatorio.",
              })}
              error={!!errors?.encapsulated_pipes}
              helperText={errors.encapsulated_pipes?.message?.toString()}
              margin="normal"
              fullWidth
              label="Tubos enfrascados"
              type={"number"}
              required
              defaultValue={"0"}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              {...register("quantity_per_pipe", {
                required: "Cantidad de piezas por tubo es obligatorio.",
              })}
              error={!!errors?.quantity_per_pipe}
              helperText={errors.quantity_per_pipe?.message?.toString()}
              margin="normal"
              fullWidth
              label="Cantidad de piezas por tubo"
              type={"number"}
              required
              defaultValue={"0"}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              {...register("returned_pieces", {
                required: "Piezas regresadas es obligatorio.",
              })}
              error={!!errors?.returned_pieces}
              helperText={errors.returned_pieces?.message?.toString()}
              margin="normal"
              fullWidth
              label="Piezas regresadas"
              type={"number"}
              required
              defaultValue={"0"}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              margin="normal"
              fullWidth
              label="Diferencia"
              className="readonly"
              value={difference}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Materiales"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showMaterialModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
              canCreate={false}
            >
              <DataGrid
                rows={materialsWithActions}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={materialColumns}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
            <EnabledMaterialModal
              visible={visibleMaterialModal}
              close={closeMaterialModal}
              title={"Nuevo material"}
              setData={handleAddMaterial}
              data={currentMaterial}
              canAdd={materialsAdd}
              setCanAdd={setMaterialsAdd}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
