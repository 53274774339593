import { DeleteButton, GridColumns, Stack } from "@pankod/refine-mui";

export const clientMaterialColumns: GridColumns = [
  {
    field: "raw_material",
    headerName: "Material",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 250,
    renderCell: (params) => {
      console.log("params", params);
      return (
        params?.row?.raw_material?.code +
        " - " +
        params?.row?.raw_material?.name
      );
    },
  },
  {
    field: "qty",
    headerName: "Cant.",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "weight",
    headerName: "Peso",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "",
    headerAlign: "center",
    field: "actions",
    minWidth: 0,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <DeleteButton
            size="small"
            hideText
            resourceNameOrRouteName={`raw-materials-data`}
            recordItemId={row.id}
          />
        </Stack>
      );
    },
  },
];

export const clientProductColumns: GridColumns = [
  {
    field: "product",
    headerName: "Producto",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 250,
    renderCell: (params) => {
      return params?.row?.product?.code + " - " + params?.row?.product?.name;
    },
  },
  {
    field: "qty",
    headerName: "Cant.",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "",
    headerAlign: "center",
    field: "actions",
    minWidth: 0,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <DeleteButton
            size="small"
            hideText
            resourceNameOrRouteName={`products-data`}
            recordItemId={row.id}
          />
        </Stack>
      );
    },
  },
];
