import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SaveButton,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IEmployee, IExcessMaterial } from "interfaces";
import { IExcessModalProps } from "../interfaces";
import { Add } from "@mui/icons-material";
import _ from "lodash-es";

export const excessMaterial: IExcessMaterial[] = [
  {
    id: "Oro",
    name: "Oro",
  },
  {
    id: "Plata",
    name: "Plata",
  },
  {
    id: "Cobre",
    name: "Cobre",
  },
  {
    id: "Overlay",
    name: "Overlay",
  },
];

export const ExcessModal: React.FC<IExcessModalProps> = ({
  visible,
  close,
  title,
  data,
  setData,
  canAdd = false,
  setCanAdd,
}) => {
  const {
    saveButtonProps,
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: "null",
    },
  });

  const { autocompleteProps: employeeAutocompleteProps } =
    useAutocomplete<IEmployee>({
      resource: "employees",
      filters: [{ field: "active", operator: "eq", value: true }],
      onSearch: (search) => [
        {
          field: "name",
          operator: "contains",
          value: search,
        },
      ],
    });

  // console.log("productsAutocompleteProps", productsAutocompleteProps);

  const cleanInputs = () => {
    setValue("employee", null);
    setValue("material", excessMaterial[0]);
    setValue("weight", "0");
    setCanAdd?.(false);
  };

  const onSubmit = (submitData: any, another: boolean) => {
    let newData = {
      ...submitData,
      action: "create",
    };
    if (data?.id) {
      if (!canAdd) {
        newData = {
          ...newData,
          id: data?.id,
          action: "update",
        };
      } else {
        newData = {
          ...newData,
          id: data?.id,
          action: "update",
          weight: parseFloat(getValues("weight")) + parseFloat(data?.weight),
        };
      }
    }
    setData(newData, another);
    cleanInputs();
  };

  useEffect(() => {
    setValue("employee", data?.employee);
    if (data?.id) {
      setValue(
        "material",
        _.find(excessMaterial, (ele) => {
          return ele.id === data?.material;
        })
      );
      if (!canAdd) {
        setValue("weight", data?.weight);
      } else {
        setValue("weight", "0");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog
      open={visible}
      onClose={() => {
        cleanInputs();
        close();
      }}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            control={control}
            name="employee"
            rules={{ required: "El campo empleado es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...employeeAutocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    return (
                      employeeAutocompleteProps?.options?.find(
                        (p) => p?.id?.toString() === item?.id?.toString()
                      )?.name ?? ""
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Empleado"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.employee}
                      helperText={errors.employee?.message?.toString()}
                      required
                    />
                  )}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="material"
            rules={{
              required: "El campo tipo de producto es obligatorio.",
            }}
            defaultValue={excessMaterial[0]}
            render={({ field }) => {
              return (
                <Autocomplete
                  options={excessMaterial}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    const label = excessMaterial?.find(
                      (p) => p?.id?.toString() === item?.id?.toString()
                    );
                    return label?.name || "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Material sobrante"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.material}
                      helperText={errors.material?.message?.toString()}
                      required
                    />
                  )}
                />
              );
            }}
          />
          <TextField
            {...register("weight", {
              required: "El campo peso sobrante es obligatorio.",
            })}
            onFocus={() => {
              if (getValues("weight") === "0") {
                setValue("weight", "");
              }
            }}
            error={!!errors?.weight}
            helperText={errors.weight?.message?.toString()}
            margin="normal"
            fullWidth
            id="weight"
            label="Peso sobrante"
            name="weight"
            type={"number"}
            defaultValue={"0"}
            required={true}
          />
          {canAdd && (
            <div>
              {data?.weight} / {+data?.weight + +watch("weight")}
            </div>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancelar</Button>
        {!data?.id && (
          <Button
            variant="outlined"
            onClick={(e: React.BaseSyntheticEvent) => {
              e.preventDefault();
              handleSubmit(
                (data) => onSubmit(data, true),
                () => false
              )(e);
            }}
            startIcon={<Add />}
          >
            Agregar otro
          </Button>
        )}
        <SaveButton
          {...saveButtonProps}
          onClick={(e: React.BaseSyntheticEvent) => {
            e.preventDefault();
            handleSubmit(
              (data) => onSubmit(data, false),
              () => false
            )(e);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
