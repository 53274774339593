import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import {
  Autocomplete,
  Box,
  Create,
  Grid,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { ICatalog, IClient } from "interfaces";
import FileInput from "components/FileInput";
import { API_URL, TOKEN_KEY, typesOrder } from "../../constants";
import axios from "axios";
import { useEffect, useState } from "react";
import { getTodayDate } from "functions";

const token = localStorage.getItem(TOKEN_KEY);

export const OrderCreate: React.FC = () => {
  const { list } = useNavigation();
  const translate = useTranslate();
  const [files, setFiles] = useState([]);

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: "orders",
      redirect: false,
      // metaData: {
      //   populate: ["employee", "client"],
      // },
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("orders.create.success"),
          type: "success",
        };
      },
    },
  });

  const { autocompleteProps: autocompletePropsClients } =
    useAutocomplete<IClient>({
      resource: "clients",
    });

  const { autocompleteProps: autocompletePropsCatalogs } =
    useAutocomplete<ICatalog>({
      resource: "catalogs",
    });

  const uploadImages = async () => {
    const formData = new FormData();

    if (files.length === 0) return null;

    files.forEach((file: any) => {
      formData.append("files", file);
    });

    const ids: any = [];

    const res = await axios.post(`${API_URL}/api/upload`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    res?.data.forEach((item: any) => {
      ids.push(item.id);
    });
    return ids[0].toString();
  };

  const { data: orders } = useCustom<any>({
    url: `${API_URL}/api/orders`,
    method: "get",
    config: {
      filters: [
        {
          field: "order",
          operator: "eq",
          value: watch("order"),
        },
        {
          field: "type",
          operator: "eq",
          value: watch("type"),
        },
      ],
    },
  });

  useEffect(() => {
    if (orders?.data?.data?.length === 0) {
      clearErrors("order");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  const onSubmit = async (data: any) => {
    if (orders?.data?.data?.length > 0) {
      setError("order", {
        type: "manual",
        message: "Ya existe una orden con este número",
      });
    } else {
      const ids = await uploadImages();
      onFinish({
        ...data,
        status: "Orden",
        order_file: ids,
      });
    }
  };

  const remove = (id: number) => {
    const newFiles = files.filter((_: any, index: number) => index !== id);
    setFiles(newFiles);
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="type"
              rules={{ required: "Tipo es obligatorio" }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={typesOrder}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.name);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        typesOrder?.find((p) => p?.name === item?.name)?.name ??
                        ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option.name === value.name;
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Tipo"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.type}
                          helperText={errors.type?.message?.toString()}
                          required
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("order", { required: "# de orden es obligatorio" })}
              error={!!errors?.order}
              helperText={errors.order?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="order"
              label="# de orden"
              name="order"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="catalog"
              // rules={{ required: "El campo sku es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompletePropsCatalogs}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompletePropsCatalogs?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.sku ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sku"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.catalog}
                        helperText={errors.catalog?.message?.toString()}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="client"
              rules={{ required: "El campo cliente es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompletePropsClients}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompletePropsClients?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.client}
                        helperText={errors.client?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("delivery_date", {
                required: "El campo fecha de entrega es obligatorio.",
              })}
              error={!!errors?.delivery_date}
              helperText={errors.delivery_date?.message?.toString()}
              margin="normal"
              fullWidth
              type={"date"}
              id="delivery_date"
              label="Fecha de entrega"
              name="delivery_date"
              defaultValue={getTodayDate()}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("final_client")}
              id="final_client"
              margin="normal"
              fullWidth
              name="final_client"
              label={"Cliente final"}
              helperText={errors?.final_client?.message?.toString()}
              error={!!errors.final_client}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("total_pieces", {
                required: "El campo tpiezas totales es obligatorio.",
              })}
              id="total_pieces"
              margin="normal"
              fullWidth
              name="total_pieces"
              label={"Piezas totales"}
              helperText={errors?.total_pieces?.message?.toString()}
              error={!!errors.total_pieces}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FileInput
              setValue={setFiles}
              remove={remove}
              acceptedFiles={files}
              multiple={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
