import React from "react";
import {
  useNavigation,
  useTranslate,
  useCan,
  useResource,
  useRouterContext,
} from "@pankod/refine-core";
import { RefineButtonTestIds } from "@pankod/refine-ui-types";
import { Button } from "@mui/material";
import { AddBoxOutlined } from "@mui/icons-material";

import { CreateButtonProps } from "@pankod/refine-mui";

/**
 * <CreateButton> uses Material UI {@link https://mui.com/components/buttons/ `<Button> component`}.
 * It uses the {@link https://refine.dev/docs/core/hooks/navigation/useNavigation#create `create`} method from {@link https://refine.dev/docs/core/hooks/navigation/useNavigation `useNavigation`} under the hood.
 * It can be useful to redirect the app to the create page route of resource}.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/buttons/create-button} for more details.
 */

interface ICreateButtonProps extends CreateButtonProps {
  recordItemId?: string;
}

export const CreateButton: React.FC<ICreateButtonProps> = ({
  resourceNameOrRouteName,
  hideText = false,
  accessControl,
  ignoreAccessControlProvider = false,
  svgIconProps,
  children,
  recordItemId,
  onClick,
  ...rest
}) => {
  const accessControlEnabled =
    accessControl?.enabled ?? !ignoreAccessControlProvider;
  const hideIfUnauthorized = accessControl?.hideIfUnauthorized ?? false;
  const { resource, resourceName } = useResource({
    resourceNameOrRouteName,
  });

  const { Link } = useRouterContext();
  const { createUrl: generateCreateUrl } = useNavigation();

  const translate = useTranslate();

  const { data } = useCan({
    resource: resourceName,
    action: "create",
    queryOptions: {
      enabled: accessControlEnabled,
    },
    params: {
      resource,
    },
  });

  const disabledTitle = () => {
    if (data?.can) return "";
    else if (data?.reason) return data.reason;
    else
      return translate(
        "buttons.notAccessTitle",
        "You don't have permission to access"
      );
  };

  const createUrl = generateCreateUrl(resource.route!);

  const { sx, ...restProps } = rest;

  if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
    return null;
  }

  return (
    <Link
      to={createUrl}
      replace={false}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (data?.can === false) {
          e.preventDefault();
          return;
        }
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      state={{ recordItemId: recordItemId }}
      style={{ textDecoration: "none" }}
    >
      <Button
        disabled={data?.can === false}
        startIcon={!hideText && <AddBoxOutlined {...svgIconProps} />}
        title={disabledTitle()}
        variant="contained"
        sx={{ minWidth: 0, padding: 0.5, ...sx }}
        data-testid={RefineButtonTestIds.CreateButton}
        {...restProps}
      >
        {hideText ? (
          <AddBoxOutlined fontSize="small" {...svgIconProps} />
        ) : (
          children ?? translate("buttons.create", "Create")
        )}
      </Button>
    </Link>
  );
};
