import { useEffect, useState } from "react";
import { IuseFinisedPiecesProps } from "../interfaces";

export const useFinisedPieces = ({
  orderQty = 0,
  currentQty = 0,
  newQty = 0,
}: IuseFinisedPiecesProps) => {
  const [qty, setQty] = useState<number>(0);
  const totalQty = +newQty.toFixed(0) + currentQty;

  useEffect(() => {
    setQty(totalQty);
  }, [totalQty]);

  return {
    orderQty,
    currentQty,
    newQty: newQty,
    totalQty: qty,
  };
};
