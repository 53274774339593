import { AuthProvider } from "@pankod/refine-core";
import { AuthHelper } from "@pankod/refine-strapi-v4";

import { TOKEN_KEY, API_URL } from "./constants";

import axios from "axios";
import secureLocalStorage from "react-secure-storage";

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

const getUser = (data: any) => {
  const {
    id,
    username,
    email,
    role: { type },
  } = data;

  const user = {
    id,
    username,
    email,
    type,
  };

  return user;
}

export const authProvider: AuthProvider = {
  login: async ({ identifier, password }) => {
    const { data, status } = await strapiAuthHelper.login(identifier, password);
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      const { data: response } = await strapiAuthHelper.me(data.jwt);
      const user = getUser(response);
      secureLocalStorage.setItem("user", user);

      // set header axios instance
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${data.jwt}`,
      };

      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    secureLocalStorage.removeItem("user");
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const userLocal = secureLocalStorage.getItem("user");

    if (userLocal) {
      return Promise.resolve(userLocal);
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const user = getUser(data);
      secureLocalStorage.setItem("user", user);

      return Promise.resolve(user);
    }

    return Promise.reject();
  },
};
