import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  DeleteButton,
  List,
  Stack,
  esES,
  DateField,
} from "@pankod/refine-mui";
import { IAbsence } from "interfaces";

export const AbsenceList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IAbsence>({
    metaData: {
      populate: ["employee"],
    },
    permanentSorter: [{ field: "createdAt", order: "desc" }],
  });

  const getDiffereceBetweenDates = (date1: Date, date2: Date) => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const columns = React.useMemo<GridColumns<IAbsence>>(
    () => [
      {
        field: "createdAt",
        headerName: "Fecha",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.createdAt) return "Sin fecha";
          return (
            <DateField
              value={row.createdAt}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      {
        field: "employee",
        headerName: "Empleado",
        flex: 1,
        valueGetter: (params) => params?.row?.employee?.name || "",
      },
      {
        field: "start_date",
        headerName: "Fecha de inicio",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.start_date) return "Sin fecha";
          return (
            <DateField
              value={row.start_date}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      {
        field: "end_date",
        headerName: "Fecha de termino",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.end_date) return "Sin fecha";
          return (
            <DateField
              value={row.end_date}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      {
        field: "",
        headerName: "Días",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: function render({ row }) {
          if (!row.end_date || !row.start_date) return "Sin fecha";
          return getDiffereceBetweenDates(
            new Date(row.start_date),
            new Date(row.end_date)
          );
        },
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
