import { useEffect, useState } from "react";
import { useList } from "@pankod/refine-core";
import { IExcess, IWeldedMaterial, IWeldedProduct } from "interfaces";

interface UseEnabledDataProps {
  id: string;
  orderId: string;
}

export const useWeldedData = ({ orderId, id }: UseEnabledDataProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const [products, setProducts] = useState<IWeldedProduct[]>([]);
  const [materials, setMaterials] = useState<IWeldedMaterial[]>([]);
  const [excesses, setExcesses] = useState<IExcess[]>([]);

  const productsUseList = useList<IWeldedProduct>({
    resource: "welded-products",
    queryOptions: {
      enabled: isEnabled,
      cacheTime: 0,
    },
    config: {
      filters: [
        {
          field: "welded",
          operator: "" as any,
          value: id,
        },
      ],
    },
    metaData: {
      populate: ["product"],
    },
  });

  const { data: productsData, isFetched: productsIsFetched } = productsUseList;

  const materialsUseList = useList<IWeldedMaterial>({
    resource: "welded-materials",
    queryOptions: {
      enabled: isEnabled,
      cacheTime: 0,
    },
    config: {
      filters: [
        {
          field: "welded",
          operator: "" as any,
          value: id,
        },
      ],
    },
    metaData: {
      populate: ["material"],
    },
  });

  const { data: materialsData, isFetched: materialsIsFetched } =
    materialsUseList;

  const weldedExcessUseList = useList<IExcess>({
    resource: "excesses",
    queryOptions: {
      enabled: isEnabled,
      cacheTime: 0,
    },
    config: {
      filters: [
        {
          field: "order",
          operator: "" as any,
          value: orderId,
        },
        {
          field: "type",
          operator: "eq",
          value: "Soldado",
        },
      ],
    },
    metaData: {
      populate: ["employee"],
    },
  });

  const { data: excessData, isFetched: excessIsFetched } = weldedExcessUseList;

  useEffect(() => {
    setIsLoading(true);
    if (id && orderId !== undefined) {
      setIsEnabled(true);
      return () => {
        setIsEnabled(false);
      };
    }
    return () => {};
  }, [id, orderId]);

  useEffect(() => {
    if (
      productsIsFetched &&
      materialsIsFetched &&
      excessIsFetched &&
      isEnabled
    ) {
      setProducts(productsData?.data || []);

      setMaterials(materialsData?.data || []);

      setExcesses(excessData?.data || []);

      setIsFetched(true);
      setIsLoading(false);
      setIsEnabled(false);
    }
    // return () => {
    //   if (isFetched && !isLoading && !isEnabled) {
    //     refetchProducts();
    //     refetchMaterials();
    //     refetchExcess();
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productsIsFetched,
    materialsIsFetched,
    excessIsFetched,
    productsData,
    materialsData,
    excessData,
    isEnabled,
  ]);

  return {
    products: products,
    materials: materials,
    excess: excesses,
    isLoading: isLoading,
    isFetched: isFetched,
  };
};
