import { HttpError } from "@pankod/refine-core";
import { Box, TextField, Create, Autocomplete, Grid } from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IRole, IUser } from "interfaces";

const roles: IRole[] = [
  {
    id: 3,
    name: "Administrador",
  },
  {
    id: 5,
    name: "Administrativo",
  },
  {
    id: 6,
    name: "Cliente",
  },
  {
    id: 4,
    name: "Consulta",
  },
];

export const UserCreate: React.FC = () => {
  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<
    IUser,
    HttpError,
    IUser & {
      role: IRole;
    }
  >();

  const onSubmit = (data: any) => {
    onFinish({
      ...data,
      confirmed: true,
    });
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="role"
              rules={{ required: "Tipo es obligatorio" }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={roles}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        roles?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Tipo"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.role}
                          helperText={errors.role?.message?.toString()}
                          required
                          autoFocus
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("username", { required: "Usuario es obligatorio" })}
              error={!!errors?.username}
              helperText={errors.username?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("email", {
                required: "Correo es obligatorio",
                validate: (value) => {
                  return value?.includes("@") || "Correo no válido";
                },
              })}
              error={!!errors?.email}
              helperText={errors.email?.message?.toString()}
              margin="normal"
              fullWidth
              id="email"
              label="Correo"
              name="email"
              type={"email"}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("password", {
                required: "Contraseña es obligatorio",
                minLength: {
                  value: 5,
                  message: "Contraseña debe tener al menos 5 caracteres",
                },
              })}
              id="password"
              margin="normal"
              fullWidth
              name="password"
              label={"Contraseña"}
              helperText={errors?.password?.message?.toString()}
              error={!!errors.password}
              type="password"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("repeat_password", {
                required: "Repetir contraseña es obligatorio",
                minLength: {
                  value: 5,
                  message: "Contraseña debe tener al menos 5 caracteres",
                },
                validate: (value) => {
                  return (
                    value === getValues("password") ||
                    "Contraseñas no coinciden"
                  );
                },
              })}
              id="repeat_password"
              margin="normal"
              fullWidth
              name="repeat_password"
              label={"Repetir contraseña"}
              helperText={errors?.repeat_password?.message?.toString()}
              error={!!errors.repeat_password}
              type="password"
              required
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
