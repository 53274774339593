import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SaveButton,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import {
  Controller,
  UseModalFormReturnType,
} from "@pankod/refine-react-hook-form";

import { IEmployee, IMaterial, IProduct } from "interfaces";

export const MaterialOutModal: React.FC<UseModalFormReturnType> = ({
  saveButtonProps,
  modal: { visible, close, title },
  register,
  control,
  setValue,
  formState: { errors },
}) => {
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const { autocompleteProps: materialAutocompleteProps } =
    useAutocomplete<IMaterial>({
      resource: "raw-materials",
      onSearch: (searchText) => [
        {
          operator: "or",
          value: [
            {
              field: "code",
              operator: "contains",
              value: searchText,
            },
            {
              field: "name",
              operator: "contains",
              value: searchText,
            },
          ],
        },
      ],
    });

  const { autocompleteProps: employeesAutocompleteProps } =
    useAutocomplete<IEmployee>({
      resource: "employees",
      filters: [{ field: "active", operator: "eq", value: true }],
      onSearch: (search) => [
        {
          field: "name",
          operator: "contains",
          value: search,
        },
      ],
    });

  const closeModal = () => {
    close();
    setCurrentProduct(null);
    setValue("raw_material", null);
    setValue("employee", null);
  };

  return (
    <Dialog
      open={visible}
      onClose={closeModal}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            control={control}
            name="employee"
            rules={{ required: "El campo empleado es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...employeesAutocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    return (
                      employeesAutocompleteProps?.options?.find(
                        (p) => p?.id?.toString() === item?.id?.toString()
                      )?.name ?? ""
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Empleado"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.employee}
                      helperText={errors.employee?.message?.toString()}
                      required
                    />
                  )}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="raw_material"
            rules={{ required: "El campo tipo de producto es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...materialAutocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                    setCurrentProduct(value);
                  }}
                  getOptionLabel={(item) => {
                    const label = materialAutocompleteProps?.options?.find(
                      (p) => p?.id?.toString() === item?.id?.toString()
                    );

                    if (!label?.name || !label?.code) return "";
                    return `${label?.code} - ${label?.name}`;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de producto"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.raw_material}
                      helperText={errors.raw_material?.message?.toString()}
                      autoFocus
                      required
                    />
                  )}
                />
              );
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Medida"
            value={currentProduct?.measure ?? ""}
            className="readonly"
          />
          <TextField
            {...register("qty", {
              required: "El campo cantidad es obligatorio.",
            })}
            error={!!errors?.qty}
            helperText={errors.qty?.message?.toString()}
            margin="normal"
            fullWidth
            id="qty"
            label="Cantidad"
            name="qty"
            required
          />
          <TextField
            {...register("weight", {
              required: "El campo peso es obligatorio.",
            })}
            error={!!errors?.weight}
            helperText={errors.weight?.message?.toString()}
            margin="normal"
            fullWidth
            id="weight"
            label="Peso"
            name="weight"
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <SaveButton {...saveButtonProps} />
      </DialogActions>
    </Dialog>
  );
};
