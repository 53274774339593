import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  DeleteButton,
  List,
  Stack,
  esES,
} from "@pankod/refine-mui";
import { IClient } from "interfaces";

export const ClientList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IClient>();

  const columns = React.useMemo<GridColumns<IClient>>(
    () => [
      { field: "name", headerName: "Nombre", flex: 1 },
      { field: "email", headerName: "Correo", flex: 1 },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
