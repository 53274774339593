import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  DeleteButton,
  List,
  Stack,
  esES,
  DateField,
} from "@pankod/refine-mui";
import { IProductData } from "interfaces";

export const ProductDataList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IProductData>({
    metaData: {
      populate: ["supplier", "employee", "product", "order.client"],
    },
    permanentSorter: [{ field: "createdAt", order: "desc" }],
  });

  const columns = React.useMemo<GridColumns<IProductData>>(
    () => [
      {
        field: "createdAt",
        headerName: "Fecha",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.createdAt) return "Sin fecha";
          return (
            <DateField
              value={row.createdAt}
              format={"DD-MMM-YYYY HH:mm"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
        minWidth: 150,
      },
      { field: "type", headerName: "Tipo", flex: 1 },
      {
        field: "supplier",
        headerName: "Proveedor",
        flex: 1,
        valueGetter: (params) => {
          console.log("yxed", params?.row);
          if (params?.row?.is_system) return "Sistema";
          if (params?.row?.order) return params?.row?.order?.client?.name;
          if (params?.row?.type === "Salida") return "N/A";
          return params?.row?.supplier?.name;
        },
      },
      {
        field: "employee",
        headerName: "Empleado",
        flex: 1,
        valueGetter: (params) => {
          if (params?.row?.type === "Entrada") return "N/A";
          return params?.row?.employee?.name;
        },
      },
      {
        field: "product",
        headerName: "Producto",
        flex: 1,
        valueGetter: (params) => {
          return `${params?.row?.product?.code} - ${params?.row?.product?.name}`;
        },
      },
      {
        field: "",
        headerName: "Medida",
        flex: 1,
        valueGetter: (params) => {
          return params?.row?.product?.measure || "";
        },
      },
      { field: "qty", headerName: "Cantidad", flex: 1 },
      {
        field: "unit_cost",
        headerName: "Costo Uni.",
        flex: 1,
        valueGetter: (params) => {
          if (params?.row?.type === "Salida") return "N/A";
          return params?.row?.unit_cost.toFixed(2);
        },
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
