import React, { useEffect } from "react";
import {
  Box,
  Grid,
  List,
  DataGrid,
  esES,
  Button,
  Edit,
  Typography,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { ISentInvoice } from "interfaces";
import { Add } from "@mui/icons-material";

import { useModal, useNavigation, useTranslate } from "@pankod/refine-core";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import _ from "lodash-es";
import { InvoicesModal } from "../enabled/modals/invoices";
import { invoicesColumns } from "./grid_columns";

export const SentEdit: React.FC = () => {
  const params = useParams();
  const { id } = params as any;
  const [invoices, setInvoices] = React.useState<ISentInvoice[]>([]);
  const [currentInvoice, setCurrentInvoice] = React.useState<ISentInvoice>();
  const [invoicessDeleted, setInvoicesDeleted] = React.useState<any[]>([]);
  const [invoicesAdd, setInvoicesAdd] = React.useState(false);
  const [totalPiecesDelivered, setTotalPiecesDelivered] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish, queryResult },
    saveButtonProps,
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      resource: "sents/edit",
      redirect: false,
      id: id,
      metaData: {
        populate: ["sent_invoices.invoice", "order"],
      },
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("sents.edit.success"),
          type: "success",
        };
      },
    },
  });

  const data = queryResult?.data?.data;

  const onSubmit = (newData: any) => {
    onFinish({
      ...newData,
      order: data?.order?.id,
      totalPiecesDelivered: totalPiecesDelivered,
      totalAmount: totalAmount,
      invoices: invoices,
      invoicesDeleted: invoicessDeleted,
    });
  };

  const {
    show: showInvoiceModal,
    close: closeInvoiceModal,
    visible: visibleInvoiceModal,
  } = useModal();

  const handleAddInvoice = (data: ISentInvoice, another: boolean = false) => {
    if (!another) {
      closeInvoiceModal();
    }
    if (data?.action === "create") {
      const invoiceId = uuid();
      const newInvoice = {
        id: invoiceId,
        invoice: data.invoice,
        amount: data.amount,
        qty: data.qty,
        delete: handleDeleteInvoice,
        update: handleUpdateInvoice,
        action: "create",
      };
      setInvoices([...invoices, newInvoice]);
    } else if (data?.action === "update") {
      const newInvoices = invoices.map((i) => {
        if (i.id === data.id) {
          return {
            ...i,
            invoice: data.invoice,
            amount: data.amount,
            qty: data.qty,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return i;
      });
      setInvoices(newInvoices);
      setCurrentInvoice(undefined);
    }
  };

  const handleDeleteInvoice = (id: string) => {
    const newInvoices = invoices.filter((m) => m.id !== id);
    setInvoices(newInvoices);
    if (!_.includes(id, "-")) {
      setInvoicesDeleted([...invoicessDeleted, id]);
    }
  };

  const handleUpdateInvoice = (data: ISentInvoice, canAdd: boolean) => {
    setCurrentInvoice(data);
    setInvoicesAdd(canAdd);
    showInvoiceModal();
  };

  const invoicesWithActions = invoices?.map((i) => ({
    ...i,
    delete: handleDeleteInvoice,
    update: handleUpdateInvoice,
    action: "none",
  }));

  useEffect(() => {
    if (data) {
      const invoices = data?.sent_invoices || [];
      setTotalPiecesDelivered(data?.total_pieces_delivered || 0);
      setTotalAmount(data?.total_amount || 0);
      setInvoices(invoices);
    }
  }, [data]);

  useEffect(() => {
    if (invoices.length) {
      const { totalPiecesDelivered, totalAmount } = invoices.reduce(
        (acc, curr) => {
          const qty = +(curr.qty || 0);
          const amount = +(curr.amount || 0);
          return {
            totalPiecesDelivered: acc.totalPiecesDelivered + qty,
            totalAmount: acc.totalAmount + amount,
          };
        },
        { totalPiecesDelivered: 0, totalAmount: 0 }
      );
      setTotalPiecesDelivered(totalPiecesDelivered);
      setTotalAmount(totalAmount);
    }
  }, [invoices]);

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: formLoading,
        onClick: handleSubmit(onSubmit),
      }}
      resource="sents"
      headerButtons={() => {
        return false;
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={5} sm={3} sx={{ marginLeft: "18px" }}>
            <Typography variant="h6" gutterBottom>
              Monto Total
            </Typography>
            <Typography variant="body1">
              {totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" gutterBottom>
              Pz. Entregadas
            </Typography>
            <Typography variant="body1">
              {totalPiecesDelivered} / {data?.order?.total_pieces}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Facturas"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showInvoiceModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
              canCreate={false}
            >
              <DataGrid
                loading={formLoading}
                rows={invoicesWithActions}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={invoicesColumns}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
            <InvoicesModal
              visible={visibleInvoiceModal}
              close={closeInvoiceModal}
              title={"Nueva factura"}
              setData={handleAddInvoice}
              data={currentInvoice}
              canAdd={invoicesAdd}
              setCanAdd={setInvoicesAdd}
            />
          </Grid>
        </Grid>
      </Box>
    </Edit>
  );
};
