import { useEffect, useState } from "react";
import { useList } from "@pankod/refine-core";
import { IAssembledProduct } from "interfaces";

interface UseEnabledDataProps {
  id: string;
  orderId: string;
}

export const useAssembledData = ({ orderId, id }: UseEnabledDataProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const [products, setProducts] = useState<IAssembledProduct[]>([]);

  const productsUseList = useList<IAssembledProduct>({
    resource: "assembled-products",
    queryOptions: {
      enabled: isEnabled,
      cacheTime: 0,
    },
    config: {
      filters: [
        {
          field: "assemble",
          operator: "" as any,
          value: id,
        },
      ],
    },
    metaData: {
      populate: ["product"],
    },
  });

  const { data: productsData, isFetched: productsIsFetched } = productsUseList;

  useEffect(() => {
    setIsLoading(true);
    if (id && orderId !== undefined) {
      setIsEnabled(true);
      return () => {
        setIsEnabled(false);
      };
    }
    return () => {};
  }, [id, orderId]);

  useEffect(() => {
    if (productsIsFetched && isEnabled) {
      setProducts(productsData?.data || []);

      setIsFetched(true);
      setIsLoading(false);
      setIsEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsIsFetched, productsData, isEnabled]);

  return {
    products: products,
    isLoading: isLoading,
    isFetched: isFetched,
  };
};
