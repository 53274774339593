import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { pdfPreviewAtom } from "recoil/atoms";
import { API_URL } from "../../constants";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@pankod/refine-mui";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { styled } from "@mui/system";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PdfPreviewer = () => {
  const pdfPreviewState = useRecoilValue(pdfPreviewAtom);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const resetPdf = useResetRecoilState(pdfPreviewAtom);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <BootstrapDialog
      onClose={() => resetPdf()}
      aria-labelledby="customized-dialog-title"
      open={!!pdfPreviewState.visible}
      fullScreen
      maxWidth="sm"
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      ></DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => resetPdf()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent
        dividers
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {pdfPreviewState.url !== "" && (
          <Document
            file={API_URL + pdfPreviewState.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} height={screenSize.height - 130} />
            {numPages > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled={pageNumber <= 1}
                  onClick={() => setPageNumber(1)}
                >
                  <ArrowBack
                    style={{ color: pageNumber <= 1 ? "light-gray" : "black" }}
                  />
                </Button>
                <span>
                  {`${pageNumber || (numPages ? 1 : "--")} de ${
                    numPages || "--"
                  }`}
                </span>
                <Button
                  disabled={pageNumber >= numPages}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  <ArrowForward
                    style={{
                      color: pageNumber >= numPages ? "light-gray" : "black",
                    }}
                  />
                </Button>
              </div>
            )}
          </Document>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};
