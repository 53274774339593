import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  DeleteButton,
  List,
  Stack,
  esES,
  DateField,
} from "@pankod/refine-mui";
import { IInvoice } from "interfaces";
import { CancelOutlined, TaskAltOutlined } from "@mui/icons-material";

export const InvoiceList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IInvoice>({
    metaData: {
      populate: ["pdf", "xml"],
    },
  });

  const columns = React.useMemo<GridColumns<IInvoice>>(
    () => [
      {
        field: "invoice",
        headerName: "Factura",
        flex: 1,
      },
      {
        field: "date_invoice",
        headerName: "Fecha",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.date_invoice) return "Sin fecha";
          return (
            <DateField
              value={row.date_invoice}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      { field: "currency", headerName: "Moneda", flex: 1 },
      {
        field: "pdf",
        headerName: "Pdf",
        flex: 1,
        align: "center",
        sortable: false,
        filterable: false,
        renderCell: function render({ row }) {
          if (row.pdf) return <TaskAltOutlined color="success" />;
          return <CancelOutlined color="error" />;
        },
      },
      {
        field: "xml",
        headerName: "Xml",
        flex: 1,
        align: "center",
        sortable: false,
        filterable: false,
        renderCell: function render({ row }) {
          if (row.xml) return <TaskAltOutlined color="success" />;
          return <CancelOutlined color="error" />;
        },
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
