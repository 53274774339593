import React from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  useAutocomplete,
  List,
  DataGrid,
  esES,
  Button,
  Create,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IEmployee, IEnabledMaterial, IEnabledProduct } from "interfaces";
import { Add } from "@mui/icons-material";
import { EnabledProductModal } from "../modals/products";
import { EnabledMaterialModal } from "../modals/materials";
import { materialColumns, productColumns } from "./grid_columns";
import { useLocation } from "react-router-dom";
import { useModal, useNavigation, useTranslate } from "@pankod/refine-core";
import { v4 as uuid } from "uuid";

type LocationState = {
  recordItemId?: string;
};

export const OrderPreEnabledCreate: React.FC = () => {
  const [products, setProducts] = React.useState<IEnabledProduct[]>([]);
  const [materials, setMaterials] = React.useState<IEnabledMaterial[]>([]);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      resource: "enableds/create",
      redirect: false,
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("enableds.create.success"),
          type: "success",
        };
      },
    },
  });

  const location = useLocation();
  const { recordItemId } = location.state as LocationState;

  const onSubmit = (data: any) => {
    onFinish({
      ...data,
      order: recordItemId,
      products: products,
      materials: materials,
    });
  };

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  const {
    show: showProductModal,
    close: closeProductModal,
    visible: visibleProductModal,
  } = useModal();

  const {
    show: showMaterialModal,
    close: closeMaterialModal,
    visible: visibleMaterialModal,
  } = useModal();

  const handleAddProduct = (
    data: IEnabledProduct,
    another: boolean = false
  ) => {
    if (!another) {
      closeProductModal();
    }
    const productId = uuid();
    const newProduct = {
      id: productId,
      product: data.product,
      quantity_pieces: data.quantity_pieces,
      weight_pieces: data.weight_pieces,
      delete: handleDeleteProduct,
    } as IEnabledProduct;
    setProducts([...products, newProduct]);
  };

  const handleDeleteProduct = (id: string) => {
    const newProducts = products.filter((p) => p.id !== id);
    setProducts(newProducts);
  };

  const handleAddMaterial = (
    data: IEnabledMaterial,
    another: boolean = false
  ) => {
    if (!another) {
      closeMaterialModal();
    }
    const materialId = uuid();
    const newMaterial = {
      id: materialId,
      material: data.material,
      delivered_weight: data.delivered_weight,
      delete: handleDeleteMaterial,
    };
    setMaterials([...materials, newMaterial]);
  };

  const handleDeleteMaterial = (id: string) => {
    const newMaterials = materials.filter((m) => m.id !== id);
    setMaterials(newMaterials);
  };

  const productsWithActions = products.map((p) => ({
    ...p,
    delete: handleDeleteProduct,
  }));

  const materialsWithActions = materials.map((m) => ({
    ...m,
    delete: handleDeleteMaterial,
  }));

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empleado"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.employee}
                        helperText={errors.employee?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <List
              breadcrumb={false}
              title={"Productos"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showProductModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
              canCreate={false}
            >
              <DataGrid
                rows={productsWithActions}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={productColumns}
              />
              <EnabledProductModal
                visible={visibleProductModal}
                close={closeProductModal}
                title={"Nuevo producto"}
                setData={handleAddProduct}
              />
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List
              breadcrumb={false}
              title={"Materiales"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showMaterialModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
              canCreate={false}
            >
              <DataGrid
                rows={materialsWithActions}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={materialColumns}
              />
              <EnabledMaterialModal
                visible={visibleMaterialModal}
                close={closeMaterialModal}
                title={"Nuevo material"}
                setData={handleAddMaterial}
              />
            </List>
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
