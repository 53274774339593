import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  DeleteButton,
  List,
  Stack,
  esES,
  DateField,
} from "@pankod/refine-mui";
import { IMaterialData } from "interfaces";

export const MaterialDataList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IMaterialData>({
    metaData: {
      populate: ["employee", "raw_material"],
    },
    permanentSorter: [{ field: "createdAt", order: "desc" }],
  });

  const columns = React.useMemo<GridColumns<IMaterialData>>(
    () => [
      {
        field: "createdAt",
        headerName: "Fecha",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.createdAt) return "Sin fecha";
          return (
            <DateField
              value={row.createdAt}
              format={"DD-MMM-YYYY HH:mm"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
        minWidth: 150,
      },
      { field: "type", headerName: "Tipo", flex: 1 },
      {
        field: "employee",
        headerName: "Empleado",
        flex: 1,
        valueGetter: (params) => {
          if (params?.row?.is_system && params?.row?.type === "Entrada")
            return "Sistema";
          if (params?.row?.type === "Entrada") return "N/A";
          return params?.row?.employee?.name;
        },
      },
      {
        field: "raw_material",
        headerName: "Producto",
        flex: 1,
        valueGetter: (params) => {
          return `${params?.row?.raw_material?.code} - ${params?.row?.raw_material?.name}`;
        },
      },
      {
        field: "",
        headerName: "Medida",
        flex: 1,
        valueGetter: (params) => {
          return params?.row?.raw_material?.measure || "";
        },
      },
      { field: "qty", headerName: "Cantidad", flex: 1 },
      {
        field: "weight",
        headerName: "Peso / Gramos",
        flex: 1,
        valueGetter: (params) => {
          return params?.row?.weight.toFixed(2);
        },
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
