import { useEffect, useState } from "react";
import { useList } from "@pankod/refine-core";
import { IEnabledMaterial, IExcess, IPasted } from "interfaces";

interface UseEnabledDataProps {
  orderId: string;
  type?: "create" | "edit";
}

export const useFretworkData = ({
  orderId,
  type = "create",
}: UseEnabledDataProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [materialIsEnabled, setMaterialIsEnabled] = useState(false);
  const [excessIsEnabled, setExcessIsEnabled] = useState(false);

  const [materials, setMaterials] = useState<IEnabledMaterial[]>([]);
  const [excesses, setExcesses] = useState<IExcess[]>([]);

  const materialsUseList = useList<IPasted>({
    resource: "pasteds",
    queryOptions: {
      enabled: materialIsEnabled,
      cacheTime: 0,
    },
    config: {
      filters: [
        {
          field: "order",
          operator: "" as any,
          value: orderId,
        },
      ],
    },
    metaData: {
      populate: ["pasted_materials.material"],
    },
  });

  const { data: materialsData, isFetched: materialsIsFetched } =
    materialsUseList;

  const enabledExcessUseList = useList<IExcess>({
    resource: "excesses",
    queryOptions: {
      enabled: excessIsEnabled,
    },
    config: {
      filters: [
        {
          field: "order",
          operator: "" as any,
          value: orderId,
        },
        {
          field: "type",
          operator: "eq",
          value: "Calado",
        },
      ],
    },
    metaData: {
      populate: ["employee"],
    },
  });

  const { data: excessData, isFetched: excessIsFetched } = enabledExcessUseList;

  useEffect(() => {
    setIsLoading(true);
    if (type === "create") {
      if (orderId !== undefined) {
        setMaterialIsEnabled(true);
        return () => {
          setMaterialIsEnabled(false);
        };
      }
    } else {
      if (orderId !== undefined) {
        setMaterialIsEnabled(true);
        setExcessIsEnabled(true);
        return () => {
          setMaterialIsEnabled(false);
          setExcessIsEnabled(false);
        };
      }
    }
  }, [type, orderId]);

  useEffect(() => {
    if (type === "create") {
      if (materialsIsFetched && materialIsEnabled) {
        setMaterials(materialsData?.data?.[0]?.pasted_materials || []);
        setIsFetched(true);
        setIsLoading(false);
        setMaterialIsEnabled(false);
      }
    } else {
      if (
        materialsIsFetched &&
        excessIsFetched &&
        materialIsEnabled &&
        excessIsEnabled
      ) {
        setMaterials(materialsData?.data?.[0]?.pasted_materials || []);
        setExcesses(excessData?.data || []);
        setIsFetched(true);
        setIsLoading(false);
        setMaterialIsEnabled(false);
        setExcessIsEnabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    materialsIsFetched,
    excessIsFetched,
    materialsData,
    excessData,
    materialIsEnabled,
    excessIsEnabled,
  ]);

  return {
    materials: materials,
    excess: excesses,
    isLoading: isLoading,
    isFetched: isFetched,
  };
};
