import { useRecoilState } from "recoil";
import { PreviewersProps } from "./index.d";
import { LightboxCustom } from "components/LightboxCustom";
import { lightboxAtom } from "../../recoil/atoms";
import { PdfPreviewer } from "components/PdfPreviewer";

export const Previewers: React.FC<PreviewersProps> = ({ files }) => {
  const [lightboxState, setLightboxState] = useRecoilState(lightboxAtom);
  return (
    <>
      <LightboxCustom
        files={files}
        open={lightboxState}
        onClose={() => setLightboxState(false)}
      />
      <PdfPreviewer />
    </>
  );
};
