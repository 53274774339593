import { Grid, ShowButton } from "@pankod/refine-mui";
import { EditButton } from "components/buttons/edit";
import { CreateButton } from "custom/components/crud/create";

export type ColorTypes =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | string;

interface ICreate {
  type?: string;
  id?: number;
  resourceNameOrRouteName?: string;
  color?: ColorTypes;
  title?: string;
  size?: number;
}

interface IEdit {
  type?: string;
  id?: number;
  resourceNameOrRouteName?: string;
  color?: ColorTypes;
  title?: string;
  size?: number;
  iconProps?: {
    color?: ColorTypes;
  };
  buttonProps?: {
    color?: ColorTypes;
  };
}

interface IActionsProps {
  params?: Array<ICreate | IEdit>;
}

export default function Actions({ params }: IActionsProps) {
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="center"
      rowSpacing={1}
    >
      <>
        {params?.map((param: any, key) => {
          switch (param.type) {
            case "create":
              return (
                <Grid
                  item
                  xs={param.size || 12}
                  style={{ display: "flex", justifyContent: "center" }}
                  key={key}
                >
                  <CreateButton
                    resourceNameOrRouteName={param.resourceNameOrRouteName}
                    hideText
                    color={param.color}
                    size="small"
                    title={param.title}
                    recordItemId={param.id}
                  />
                </Grid>
              );
            case "edit":
              return (
                <Grid
                  item
                  xs={param.size || 12}
                  style={{ display: "flex", justifyContent: "center" }}
                  key={key}
                >
                  <EditButton
                    resourceNameOrRouteName={param.resourceNameOrRouteName}
                    hideText
                    title={param.title}
                    recordItemId={param.id.toString()}
                    iconProps={param.iconProps}
                    buttonProps={param.buttonProps}
                    Icon={param.icon}
                  />
                </Grid>
              );
            case "show":
              return (
                <Grid
                  item
                  xs={param.size || 12}
                  style={{ display: "flex", justifyContent: "center" }}
                  key={key}
                >
                  <ShowButton
                    resourceNameOrRouteName={param.resourceNameOrRouteName}
                    hideText
                    color={param.color}
                    size="small"
                    title={param.title}
                    recordItemId={param.id.toString()}
                  />
                </Grid>
              );
            case "modal":
              if (!param.id) {
                console.log(param);
              }
              return (
                <Grid
                  item
                  xs={param.size || 12}
                  style={{ display: "flex", justifyContent: "center" }}
                  key={key}
                >
                  <EditButton
                    hideText
                    title={param.title}
                    recordItemId={param?.id?.toString()}
                    iconProps={param.iconProps}
                    buttonProps={param.buttonProps}
                    Icon={param.icon}
                    onClick={param.onClick}
                  />
                </Grid>
              );
            default:
              return null;
          }
        })}
      </>
    </Grid>
  );
}
