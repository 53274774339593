import React from "react";
import { useUpdate } from "@pankod/refine-core";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  DateField,
  DialogActions,
  Fade,
  Grid,
  Typography,
} from "@pankod/refine-mui";
import { Box, Dialog, DialogContent, DialogTitle } from "@pankod/refine-mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IOrderOpenModalProps } from "./interfaces";

const Col = ({ title, value }: { title: string; value: any }) => {
  return (
    <>
      <Typography component={"div"} variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography component={"div"} variant="body1">
        {value}
      </Typography>
    </>
  );
};

export const OrderDetailsModal: React.FC<IOrderOpenModalProps> = ({
  visible,
  close,
  title,
  data,
}) => {
  const { mutate } = useUpdate();
  const [expanded, setExpanded] = React.useState(true);

  const openOrder = () => {
    mutate(
      {
        resource: "orders",
        values: {
          status: "Orden",
        },
        id: data?.id as number,
      },
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  const closeOrder = () => {
    mutate(
      {
        resource: "orders",
        values: {
          status: "Terminada",
        },
        id: data?.id as number,
      },
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  const cancelOrder = () => {
    mutate(
      {
        resource: "orders",
        values: {
          status: "Cancelada",
        },
        id: data?.id as number,
      },
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Dialog open={visible} onClose={close} fullWidth maxWidth={"xl"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Accordion
                expanded={expanded}
                onChange={handleExpansion}
                TransitionComponent={
                  Fade as AccordionProps["TransitionComponent"]
                }
                TransitionProps={{ timeout: 200 }}
                sx={{
                  "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                  "& .MuiAccordionDetails-root": {
                    display: expanded ? "block" : "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h5">Orden</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Col title="Tipo" value={data?.type} />
                    </Grid>
                    <Grid item xs={2}>
                      <Col title="# Orden" value={data?.order} />
                    </Grid>
                    <Grid item xs={2}>
                      <Col title="SKU" value={data?.catalog?.sku} />
                    </Grid>
                    <Grid item xs={2}>
                      <Col
                        title="Fecha de entrega"
                        value={
                          <DateField
                            value={data?.delivery_date}
                            format={"DD / MMM / YYYY"}
                            locales={"es-mx"}
                            style={{ textTransform: "uppercase" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Col title="Piezas totales" value={data?.total_pieces} />
                    </Grid>
                    <Grid item xs={2}>
                      <Col title="Status" value={data?.status} />
                    </Grid>
                    <Grid item xs={6}>
                      <Col title="Cliente" value={data?.client?.name} />
                    </Grid>
                    <Grid item xs={6}>
                      <Col title="Cliente final" value={data?.final_client} />
                    </Grid>
                    <Grid item xs={6}>
                      <Col title="Comentarios" value={data?.comments} />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {data?.enabled && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography variant="h5">Habilitado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.enabled?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PTH"
                          value={data?.enabled?.pth.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.enabled?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRH"
                          value={data?.enabled?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.enabled?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.pasted && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography variant="h5">Pegado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.pasted?.employee?.name}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.fretwork && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <Typography variant="h5">Calado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.fretwork?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Calado"
                          value={data?.fretwork?.fretwork.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.fretwork?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.welded && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5-content"
                    id="panel5-header"
                  >
                    <Typography variant="h5">Soldado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.welded?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRH"
                          value={data?.enabled?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PC"
                          value={data?.fretwork?.fretwork.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col title="PTS" value={data?.welded?.pts.toFixed(2)} />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.welded?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRS"
                          value={data?.welded?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.welded?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.polish && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6-content"
                    id="panel6-header"
                  >
                    <Typography variant="h5">Pulido</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.polish?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.polish?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRP"
                          value={data?.polish?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRS"
                          value={data?.welded?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.polish?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.engrave && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7-content"
                    id="panel7-header"
                  >
                    <Typography variant="h5">Grabado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.engrave?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.engrave?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRG"
                          value={data?.engrave?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRP"
                          value={data?.polish?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.engrave?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.detail && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7-content"
                    id="panel7-header"
                  >
                    <Typography variant="h5">Detallado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.detail?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.detail?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRD"
                          value={data?.detail?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRG"
                          value={data?.engrave?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.detail?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.assemble && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8-content"
                    id="panel8-header"
                  >
                    <Typography variant="h5">Montado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.assemble?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.assemble?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRM"
                          value={data?.assemble?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRD"
                          value={data?.detail?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.assemble?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.luster && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9-content"
                    id="panel9-header"
                  >
                    <Typography variant="h5">Lustre 2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.luster?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.luster?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRL"
                          value={data?.luster?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRM"
                          value={data?.assemble?.real_weight.toFixed(2)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Merma"
                          value={data?.luster?.decrease.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.painted && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10-content"
                    id="panel10-header"
                  >
                    <Typography variant="h5">Pintado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.painted?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.painted?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.wrapped && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel11-content"
                    id="panel11-header"
                  >
                    <Typography variant="h5">Envuelto</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Empleado"
                          value={data?.wrapped?.employee?.name}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas terminadas"
                          value={`${data?.wrapped?.finished_pieces} / ${data?.total_pieces}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="PRE"
                          value={data?.wrapped?.real_weight?.toFixed(2) ?? 0}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.sent && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel12-content"
                    id="panel12-header"
                  >
                    <Typography variant="h5">Enviado</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Col
                          title="Monto total"
                          value={data?.sent?.total_amount.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Col
                          title="Piezas entregadas"
                          value={`${data?.sent?.total_pieces_delivered} / ${data?.total_pieces}`}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {data?.status !== "Terminada" && data?.status !== "Cancelada" ? (
          <>
            <Button onClick={closeOrder} variant="contained" color="info">
              Cerrar orden
            </Button>
            <Button onClick={cancelOrder} variant="contained" color="error">
              Cancelar orden
            </Button>
          </>
        ) : (
          <Button onClick={openOrder} variant="contained" color="info">
            Abrir orden
          </Button>
        )}
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={close}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
