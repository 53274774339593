import { FC, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { MIME_TYPES_DOCS } from "../../constants";
import { downloadFile } from "../../functions";
import { DeleteForever, Download, Preview } from "@mui/icons-material";
import { useSetRecoilState } from "recoil";
import { lightboxAtom, pdfPreviewAtom } from "recoil/atoms";

interface IFileInputProps {
  setValue: any;
  remove: any;
  acceptedFiles: any;
  multiple?: boolean;
  label?: string;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Files = ({ files = [], remove }: any) => {
  const setLightboxState = useSetRecoilState(lightboxAtom);
  const previewState = useSetRecoilState(pdfPreviewAtom);

  const convertBytes = (bytes: number) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    if (bytes === 0) {
      return "n/a";
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    if (i === 0) {
      return `${bytes} ${sizes[i]}`;
    }

    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  const showPreviewHandler = ({ type, path }: any) => {
    if (type !== ".pdf") {
      setLightboxState(true);
    } else {
      previewState({
        url: path,
        visible: true,
      });
    }
  };

  return (
    <ul>
      {files.map((file: any, index: number) => (
        <li key={file.path} style={{ display: "flex", alignItems: "center" }}>
          {file.path} - {convertBytes(file.size)}
          {file.url && file.ext !== ".xml" && (
            <Preview
              style={{ cursor: "pointer", marginLeft: 20 }}
              onClick={() =>
                showPreviewHandler({ type: file.ext, path: file.url })
              }
            />
          )}
          {file.url && (
            <Download
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={() => downloadFile(file.url, file.path)}
            />
          )}
          <DeleteForever
            style={{ color: "red", cursor: "pointer", marginLeft: 10 }}
            onClick={() => remove(index)}
          />
        </li>
      ))}
    </ul>
  );
};

const FileInput: FC<IFileInputProps> = ({
  setValue,
  remove,
  acceptedFiles = [],
  multiple = true,
  label = "Archivos",
}) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: MIME_TYPES_DOCS,
      onDrop: (files) => {
        if (multiple) {
          const newFiles = [...acceptedFiles, ...files];
          setValue(newFiles);
        } else {
          setValue(files);
        }
      },
      multiple: multiple,
    });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Arrastre y suelte archivos aquí, o haga clic para seleccionar archivos
        </p>
      </div>
      <aside>
        <h4>{label}</h4>
        <Files files={acceptedFiles} remove={remove} />
      </aside>
    </div>
  );
};
export default FileInput;
