import { atom } from "recoil";

export const lightboxAtom = atom({
    key: 'lightboxState',
    default: false,
});

export const pdfPreviewAtom = atom({
    key: 'pdfPrevieState',
    default: {
        url: "",
        visible: false,
    },
});