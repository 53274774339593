import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  useAutocomplete,
  List,
  DataGrid,
  esES,
  Button,
  Create,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IEmployee, IExcess, IPastedMaterial } from "interfaces";
import { Add } from "@mui/icons-material";

import { useModal, useNavigation, useTranslate } from "@pankod/refine-core";
import { v4 as uuid } from "uuid";

import _ from "lodash-es";
import { useLocation } from "react-router-dom";
import { excessColumns } from "../enabled/grid_columns";
import { useFretworkData } from "../enabled/hooks/useFretworkData";
import { materialColumns } from "./grid_columns";
import { ExcessModal } from "../enabled/modals/excess";

type LocationState = {
  recordItemId?: string;
};

export const FretworkCreate: React.FC = () => {
  const [materials, setMaterials] = React.useState<IPastedMaterial[]>([]);

  const [excess, setExcess] = React.useState<IExcess[]>([]);
  const [currentExcess, setCurrentExcess] = React.useState<IExcess>();
  const [excessDeleted, setExcessDeleted] = React.useState<any[]>([]);
  const [excessAdd, setExcessAdd] = React.useState(false);

  const [fretwork, setFretwork] = React.useState<number>(0);
  const [decrease, setDecrease] = React.useState<number>(0);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      resource: "fretworks/create",
      redirect: false,
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("fretworks.create.success"),
          type: "success",
        };
      },
    },
  });

  const location = useLocation();
  const { recordItemId } = location.state as LocationState;

  const {
    materials: materialsData,
    excess: excessData,
    isLoading,
    isFetched,
  } = useFretworkData({
    orderId: recordItemId || "0",
  });

  useEffect(() => {
    if (isFetched) {
      setMaterials(materialsData);
      setExcess(excessData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  const onSubmit = (newData: any) => {
    onFinish({
      ...newData,
      order: recordItemId,
      decrease: decrease,
      excess: excess,
      excess_deleted: excessDeleted,
    });
  };

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  const {
    show: showExcessModal,
    close: closeExcessModal,
    visible: visibleExcessModal,
  } = useModal();

  const handleDeleteExcess = (id: string) => {
    const newExcess = excess.filter((m) => m.id !== id);
    setExcess(newExcess);
    if (!_.includes(id, "-")) {
      setExcessDeleted([...excessDeleted, id]);
    }
  };

  const handleAddExcess = (data: IExcess, another: boolean = false) => {
    if (!another) {
      closeExcessModal();
    }

    if (data?.action === "create") {
      const excessId = uuid();
      const newExcess = {
        id: excessId,
        employee: data.employee,
        material: _.get(data.material, "id", undefined),
        weight: data.weight,
        delete: handleDeleteExcess,
        update: handleUpdateExcess,
        action: "create",
      };
      setExcess([...excess, newExcess]);
    } else if (data?.action === "update") {
      const newExcess = excess.map((e) => {
        if (e.id === data.id) {
          return {
            ...e,
            employee: data.employee,
            material: _.get(data.material, "id", undefined),
            weight: data.weight,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return e;
      });
      setExcess(newExcess);
      setCurrentExcess(undefined);
    }
  };

  const handleUpdateExcess = (data: IExcess, canAdd: boolean) => {
    setCurrentExcess(data);
    setExcessAdd(canAdd);
    showExcessModal();
  };

  const excessWithActions = excess?.map((e) => ({
    ...e,
    delete: handleDeleteExcess,
    update: handleUpdateExcess,
    action: "none",
  }));

  const getTotal = () => {
    setIsProcessing(true);
    const materialsTotal = materials?.reduce((acc, curr) => {
      return acc + curr.delivered_weight;
    }, 0);

    const excessTotal = excess?.reduce((acc, curr) => {
      return acc + +curr.weight;
    }, 0);

    const total = materialsTotal - (excessTotal + fretwork);
    setDecrease(+total.toFixed(2));
  };

  useEffect(() => {
    if (isFetched && !isLoading) {
      getTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials, excess, fretwork]);

  useEffect(() => {
    setIsProcessing(false);
  }, [decrease]);

  return (
    <Create
      isLoading={formLoading || isProcessing || isLoading}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: formLoading,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empleado"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.employee}
                        helperText={errors.employee?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("fretwork")}
              margin="normal"
              fullWidth
              id="fretwork"
              label="Calado"
              name="fretwork"
              defaultValue={0}
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  setFretwork(+value);
                } else {
                  setFretwork(0);
                }
              }}
              onFocus={() => {
                if (getValues("fretwork") === "0") {
                  setValue("fretwork", "");
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              id="decrease"
              label="Merma"
              name="decrease"
              defaultValue={0}
              value={decrease}
              type="number"
              className="readonly"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <List breadcrumb={false} title={"Materiales"} canCreate={false}>
              <DataGrid
                rows={materials}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={materialColumns}
                loading={isLoading}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
          </Grid>
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Sobrantes"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showExcessModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
              canCreate={false}
            >
              <DataGrid
                rows={excessWithActions}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={excessColumns}
                loading={isLoading}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
            <ExcessModal
              visible={visibleExcessModal}
              close={closeExcessModal}
              title={"Nuevo sobrante"}
              setData={handleAddExcess}
              data={{
                employee: getValues("employee"),
                ...currentExcess,
              }}
              canAdd={excessAdd}
              setCanAdd={setExcessAdd}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
