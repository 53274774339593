import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SaveButton,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IMaterial } from "interfaces";
import { useEffect } from "react";

export interface IEnabledModalProps {
  visible: boolean;
  close: () => void;
  title: string;
  data?: any;
  currentData?: any;
  setData: (data: any, another: boolean) => void;
  canAdd?: boolean;
  setCanAdd?: (canAdd: boolean) => void;
}

export const EnabledMaterialModal: React.FC<IEnabledModalProps> = ({
  visible,
  close,
  title,
  data,
  setData,
  canAdd = false,
  setCanAdd,
}) => {
  const {
    saveButtonProps,
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: "null",
    },
  });

  const { autocompleteProps: productsAutocompleteProps } =
    useAutocomplete<IMaterial>({
      resource: "raw-materials",
      onSearch: (searchText) => [
        {
          operator: "or",
          value: [
            {
              field: "code",
              operator: "contains",
              value: searchText,
            },
            {
              field: "name",
              operator: "contains",
              value: searchText,
            },
          ],
        },
      ],
    });

  const cleanInputs = () => {
    setValue("material", null);
    setValue("delivered_weight", "0");
    setCanAdd?.(false);
  };

  const onSubmit = (submitData: any, another: boolean) => {
    let newData = {};
    if (data?.id) {
      if (!canAdd) {
        newData = {
          ...submitData,
          id: data?.id,
          action: "update",
        };
      } else {
        newData = {
          ...submitData,
          id: data?.id,
          action: "update",
          delivered_weight:
            parseFloat(getValues("delivered_weight")) +
            parseFloat(data?.delivered_weight),
        };
      }
    } else {
      newData = {
        ...submitData,
        action: "create",
      };
    }
    setData(newData, another);
    cleanInputs();
  };

  useEffect(() => {
    setValue("delivered_weight", "0");
    if (data?.id) {
      setValue("material", data?.material);
      if (!canAdd) {
        setValue("delivered_weight", data?.delivered_weight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog
      open={visible}
      onClose={() => {
        cleanInputs();
        close();
      }}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            control={control}
            name="material"
            rules={{ required: "El campo tipo de material es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...productsAutocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    const label = productsAutocompleteProps?.options?.find(
                      (p) => p?.id?.toString() === item?.id?.toString()
                    );

                    if (!label?.name || !label?.code) return "";
                    return `${label?.code} - ${label?.name}`;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Materia prima"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.material}
                      helperText={errors.material?.message?.toString()}
                      autoFocus
                      required
                    />
                  )}
                />
              );
            }}
          />
          <TextField
            {...register("delivered_weight", {
              required: "El campo peso entregado es obligatorio.",
            })}
            onFocus={() => {
              if (getValues("delivered_weight") === "0") {
                setValue("delivered_weight", "");
              }
            }}
            error={!!errors?.delivered_weight}
            helperText={errors.delivered_weight?.message?.toString()}
            margin="normal"
            fullWidth
            id="delivered_weight"
            label="Peso material"
            name="delivered_weight"
            type={"number"}
            required
            defaultValue={""}
          />
          {canAdd && (
            <div>
              {data?.delivered_weight} /{" "}
              {+data?.delivered_weight + +watch("delivered_weight")}
            </div>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        {!data?.id && (
          <Button
            variant="outlined"
            onClick={(e: React.BaseSyntheticEvent) => {
              e.preventDefault();
              handleSubmit(
                (data) => onSubmit(data, true),
                () => false
              )(e);
            }}
            startIcon={<Add />}
          >
            Agregar otro
          </Button>
        )}
        <SaveButton
          {...saveButtonProps}
          onClick={(e: React.BaseSyntheticEvent) => {
            e.preventDefault();
            handleSubmit(
              (data) => onSubmit(data, false),
              () => false
            )(e);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
