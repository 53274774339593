import { useEffect, useState } from "react";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { Edit, Box, TextField, Grid, Autocomplete } from "@pankod/refine-mui";
import { API_URL } from "../../constants";
import { IRecovery, IRecoveryDetail } from "interfaces";
import { useCustom } from "@pankod/refine-core";

const excessMaterial = ["Oro", "Plata", "Cobre", "Overlay"];

const TYPES = {
  Plata: "silver",
  Oro: "gold",
  Overlay: "overlay",
  Cobre: "copper",
};

export const RecoveryEdit: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recoveryData, setRecoveryData] = useState<IRecovery | null>({
    total: 0,
    totalMaterial: 0,
    difference: 0,
  });
  const [currentData, setCurrentData] = useState<IRecoveryDetail | null>(null);

  const {
    refineCore: { formLoading, onFinish, queryResult },
    saveButtonProps,
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const recoveryResult = queryResult?.data?.data;

  const [currentMaterial, setCurrentMaterial] = useState(excessMaterial[0]);

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    onFinish({
      ...data,
    });
  };

  const { data: recovery } = useCustom<any>({
    url: `${API_URL}/api/recoveries/1`,
    method: "get",
  });

  useEffect(() => {
    if (recovery && currentData) {
      const data = recovery?.data?.data?.attributes;
      const material = TYPES[currentMaterial as keyof typeof TYPES];
      const totalMaterial =
        currentData.material === currentMaterial
          ? (data?.[material] + currentData?.delivered).toFixed(2)
          : data?.[material];
      setRecoveryData({
        total: (data?.total + currentData?.delivered).toFixed(2) ?? 0,
        totalMaterial: totalMaterial ?? 0,
      });
    }
  }, [recovery, currentMaterial, currentData]);

  useEffect(() => {
    if (recoveryResult) {
      setCurrentData(recoveryResult);
      setCurrentMaterial(recoveryResult?.material);
    }
  }, [recoveryResult]);

  return (
    <Edit
      isLoading={formLoading || isSubmitting}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              fullWidth
              id="total"
              label="Sobrante general"
              name="total"
              value={(
                (recoveryData?.total ?? 0) - (watch("delivered") ?? 0)
              ).toFixed(2)}
              className="readonly"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="material"
              rules={{ required: "Moneda es obligatoria" }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={excessMaterial}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      setCurrentMaterial(value);
                    }}
                    getOptionLabel={(item) => {
                      return item;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option === value;
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={"Material sobrante"}
                          margin="normal"
                          variant="outlined"
                          error={!!errors.material}
                          helperText={errors.material?.message?.toString()}
                          required
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              fullWidth
              id="total"
              label={`Sobrante por material (${currentMaterial})`}
              name="total"
              value={(
                (recoveryData?.totalMaterial ?? 0) - (watch("delivered") ?? 0)
              ).toFixed(2)}
              className="readonly"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("delivered", {
                required: "Peso entregado es obligatorio",
              })}
              error={!!errors?.delivered}
              helperText={errors.delivered?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="delivered"
              label="Peso entregado"
              name="delivered"
              type="number"
              defaultValue={0}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("recovered", {
                required: "Peso regresado es obligatorio",
              })}
              error={!!errors?.delivered}
              helperText={errors.delivered?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="recovered"
              label="Peso regresado"
              name="recovered"
              type="number"
              defaultValue={0}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              fullWidth
              id="difference"
              label={`Diferencia`}
              name="difference"
              value={(
                (watch("delivered") ?? 0) - (watch("recovered") ?? 0)
              ).toFixed(2)}
              className="readonly"
            />
          </Grid>
        </Grid>
      </Box>
    </Edit>
  );
};
