import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SaveButton,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import {
  Controller,
  UseModalFormReturnType,
} from "@pankod/refine-react-hook-form";
import { getTodayDate } from "functions";

import { IEmployee } from "interfaces";

export const CreateIncapacityModal: React.FC<UseModalFormReturnType> = ({
  saveButtonProps,
  refineCore: { id },
  modal: { visible, close, title },
  register,
  control,
  setValue,
  formState: { errors },
}) => {
  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    defaultValue: id?.toString(),
    defaultValueQueryOptions: {
      onSuccess: (data) => {
        setValue("employee", data?.data?.[0]);
      },
    },
  });

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            control={control}
            name="employee"
            rules={{ required: "El campo empleado es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...autocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    return (
                      autocompleteProps?.options?.find(
                        (p) => p?.id?.toString() === item?.id?.toString()
                      )?.name ?? ""
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Empleado"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.employee}
                      helperText={errors.employee?.message?.toString()}
                    />
                  )}
                />
              );
            }}
          />
          <TextField
            {...register("start_date", {
              required: "El campo fecha de inicio es obligatorio.",
            })}
            error={!!errors?.start_date}
            helperText={errors.start_date?.message?.toString()}
            margin="normal"
            fullWidth
            type={"date"}
            id="start_date"
            label="Fecha de inicio"
            name="start_date"
            defaultValue={getTodayDate()}
            required
          />

          <TextField
            {...register("end_date", {
              required: "El campo fecha de termino es obligatorio.",
            })}
            error={!!errors?.end_date}
            helperText={errors.end_date?.message?.toString()}
            margin="normal"
            fullWidth
            type={"date"}
            id="end_date"
            label="Fecha de termino"
            name="end_date"
            defaultValue={getTodayDate()}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} />
      </DialogActions>
    </Dialog>
  );
};
