import {
  useNavigation,
  useResource,
  useRouterContext,
  useTranslate,
} from "@pankod/refine-core";
import { Button } from "@pankod/refine-mui";
import { IEditButtonProps } from "./interfaces";
import { EditOutlined } from "@mui/icons-material";

export const EditButton: React.FC<IEditButtonProps> = ({
  resourceNameOrRouteName,
  recordItemId,
  onClick,
  hideText = false,
  Icon = EditOutlined,
  svgIconProps = {},
  children,
  iconProps = {},
  buttonProps = {},
  style,
  url,
  ...rest
}) => {
  const { resource, id } = useResource({
    resourceNameOrRouteName,
    recordItemId,
  });

  const translate = useTranslate();

  const { editUrl: generateEditUrl } = useNavigation();
  const { Link } = useRouterContext();

  const editUrl = url ? url : generateEditUrl(resource.route!, id!);

  const { sx, ...restProps } = rest;

  return (
    <Link
      to={editUrl}
      replace={false}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      style={{ textDecoration: "none" }}
    >
      <Button
        style={style}
        startIcon={
          !hideText && (
            <Icon
              sx={{ selfAlign: "center" }}
              {...svgIconProps}
              {...iconProps}
            />
          )
        }
        sx={{ minWidth: 0, padding: 0.5, ...sx }}
        {...buttonProps}
        {...restProps}
      >
        {hideText ? (
          <Icon fontSize="small" {...svgIconProps} {...iconProps} />
        ) : (
          children ?? translate("buttons.edit", "Edit")
        )}
      </Button>
    </Link>
  );
};
