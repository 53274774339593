import { useEffect, useState } from "react";
import { IuseRealWeightProps } from "../interfaces";

export const useRealWeight = ({
  currentRealWeight = 0,
  newRealWeight = 0,
}: IuseRealWeightProps) => {
  const [realWeight, setRealWeight] = useState<number>(0);

  const totalRealWeight: number =
    +newRealWeight.toFixed(2) + +currentRealWeight.toFixed(2);

  useEffect(() => {
    setRealWeight(totalRealWeight);
  }, [totalRealWeight]);
  return {
    currentRealWeight: +currentRealWeight.toFixed(2),
    totalRealWeight: +realWeight.toFixed(2),
  };
};
