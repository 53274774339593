import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  TextField,
  Grid,
  Autocomplete,
  Typography,
} from "@pankod/refine-mui";
import { IInvoiceCurrencyType } from "interfaces";
import { useEffect, useState } from "react";
import FileInput from "components/FileInput";
import { getTodayDate, uploadFiles } from "functions";
import { Previewers } from "components/Previewers";

const typesCurrency: IInvoiceCurrencyType[] = [
  {
    name: "MXN",
  },
  {
    name: "USD",
  },
  {
    name: "EUR",
  },
];

export const InvoiceEdit: React.FC = () => {
  const [pdf, setPdf] = useState([]);
  const [xml, setXml] = useState([]);
  const {
    refineCore: { formLoading, queryResult, onFinish },
    saveButtonProps,
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      metaData: {
        populate: ["pdf", "xml"],
      },
    },
  });

  const onSubmit = async (data: any) => {
    const { currency, ...rest } = data;
    const pdfId = await uploadFiles(pdf);
    const xmlId = await uploadFiles(xml);

    onFinish({
      ...rest,
      pdf: pdfId,
      xml: xmlId,
      currency: currency.name,
    });
  };

  useEffect(() => {
    if (queryResult?.data?.data?.pdf) {
      const pdfs = queryResult?.data?.data?.pdf;
      let data = [];
      if (Array.isArray(pdfs)) {
        data = pdfs.map((pdf: any) => {
          return {
            id: pdf?.id,
            path: pdf?.name,
            size: pdf?.size * 1024,
            url: pdf?.url,
            ext: pdf?.ext,
          };
        });
      } else {
        const pdf = {
          id: pdfs?.id,
          path: pdfs?.name,
          size: pdfs?.size * 1024,
          url: pdfs?.url,
          ext: pdfs?.ext,
        };
        data.push(pdf);
      }
      setPdf(data as any);
    }

    if (queryResult?.data?.data?.xml) {
      const xmls = queryResult?.data?.data?.xml;
      let data = [];
      if (Array.isArray(xmls)) {
        data = xmls.map((xml: any) => {
          return {
            id: xml?.id,
            path: xml?.name,
            size: xml?.size * 1024,
            url: xml?.url,
            ext: xml?.ext,
          };
        });
      } else {
        const xml = {
          id: xmls?.id,
          path: xmls?.name,
          size: xmls?.size * 1024,
          url: xmls?.url,
          ext: xmls?.ext,
        };
        data.push(xml);
      }
      setXml(data as any);
    }
  }, [queryResult?.data?.data?.pdf, queryResult?.data?.data?.xml, setValue]);

  useEffect(() => {
    if (queryResult?.data?.data?.currency) {
      const currency = queryResult?.data?.data?.currency;
      setValue("currency", { name: currency });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult?.data?.data?.currency]);

  const removePDF = (id: number) => {
    const newFiles = pdf.filter((_: any, index: number) => index !== id);
    setPdf(newFiles);
  };

  const removeXML = (id: number) => {
    const newFiles = xml.filter((_: any, index: number) => index !== id);
    setXml(newFiles);
  };

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: formLoading,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("invoice", {
                required: "# de factura es obligatorio",
              })}
              error={!!errors?.invoice}
              helperText={errors.invoice?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="order"
              label="# de factura"
              name="invoice"
              defaultValue={" "}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("date_invoice", {
                required: "El campo fecha de entrega es obligatorio.",
              })}
              error={!!errors?.date_invoice}
              helperText={errors.date_invoice?.message?.toString()}
              margin="normal"
              fullWidth
              type={"date"}
              id="date_invoice"
              label="Fecha"
              name="date_invoice"
              defaultValue={getTodayDate()}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="currency"
              rules={{ required: "Moneda es obligatoria" }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={typesCurrency}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        typesCurrency?.find((p) => p?.name === item?.name)
                          ?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option.name === value.name;
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Moneda"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.currency}
                          helperText={errors.currency?.message?.toString()}
                          required
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              PDF
            </Typography>
            <FileInput
              setValue={setPdf}
              remove={removePDF}
              acceptedFiles={pdf}
              multiple={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              XML
            </Typography>
            <FileInput
              setValue={setXml}
              remove={removeXML}
              acceptedFiles={xml}
              multiple={false}
            />
          </Grid>
        </Grid>
      </Box>
      <Previewers files={pdf} />
    </Edit>
  );
};
