import {
  Box,
  TextField,
  Create,
  Grid,
  Autocomplete,
  Typography,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import FileInput from "components/FileInput";
import { IInvoiceCurrencyType } from "interfaces";
import { useState } from "react";
import { getTodayDate, uploadFiles } from "functions";

const typesCurrency: IInvoiceCurrencyType[] = [
  {
    name: "MXN",
  },
  {
    name: "USD",
  },
  {
    name: "EUR",
  },
];

export const InvoiceCreate: React.FC = () => {
  const [pdf, setPdf] = useState([]);
  const [xml, setXml] = useState([]);
  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      invoice: undefined,
      date_invoice: undefined,
      currency: { name: "MXN" },
    },
  });

  const onSubmit = async (data: any) => {
    const { currency, ...rest } = data;
    const pdfId = await uploadFiles(pdf);
    const xmlId = await uploadFiles(xml);

    onFinish({
      ...rest,
      pdf: pdfId,
      xml: xmlId,
      currency: currency.name,
    });
  };

  const removePDF = (id: number) => {
    const newFiles = pdf.filter((_: any, index: number) => index !== id);
    setPdf(newFiles);
  };

  const removeXML = (id: number) => {
    const newFiles = xml.filter((_: any, index: number) => index !== id);
    setXml(newFiles);
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("invoice", {
                required: "# de factura es obligatorio",
              })}
              error={!!errors?.invoice}
              helperText={errors.invoice?.message?.toString()}
              margin="normal"
              required
              fullWidth
              id="order"
              label="# de factura"
              name="invoice"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register("date_invoice", {
                required: "El campo fecha de entrega es obligatorio.",
              })}
              error={!!errors?.date_invoice}
              helperText={errors.date_invoice?.message?.toString()}
              margin="normal"
              fullWidth
              type={"date"}
              id="date_invoice"
              label="Fecha"
              name="date_invoice"
              defaultValue={getTodayDate()}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name="currency"
              rules={{ required: "Moneda es obligatoria" }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    options={typesCurrency}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        typesCurrency?.find((p) => p?.name === item?.name)
                          ?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option.name === value.name;
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Moneda"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.currency}
                          helperText={errors.currency?.message?.toString()}
                          required
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              PDF
            </Typography>
            <FileInput
              setValue={setPdf}
              remove={removePDF}
              acceptedFiles={pdf}
              multiple={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              XML
            </Typography>
            <FileInput
              setValue={setXml}
              remove={removeXML}
              acceptedFiles={xml}
              multiple={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
