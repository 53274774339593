import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  DeleteButton,
  List,
  Stack,
  esES,
} from "@pankod/refine-mui";
import { IUser } from "interfaces";

type RoleMapping = {
  [key: string]: string;
};

const roleMapping: RoleMapping = {
  Administrator: "Administrador",
  Administrative: "Administrativo",
  Client: "Cliente",
  Reader: "Consulta",
};

export const UserList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IUser>({
    metaData: {
      populate: ["role"],
    },
  });

  const columns = React.useMemo<GridColumns<IUser>>(
    () => [
      { field: "username", headerName: "Usuario", flex: 1 },
      {
        field: "role",
        headerName: "Tipo",
        flex: 1,
        valueGetter: (params) =>
          roleMapping[params.row.role.name] || params.row.role.name,
      },
      { field: "email", headerName: "Correo", flex: 1 },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
      />
    </List>
  );
};
