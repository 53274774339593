import { useForm } from "@pankod/refine-react-hook-form";
import { Edit, Box, TextField, Grid } from "@pankod/refine-mui";

export const ClientEdit: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm();

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("name", { required: "Nombre es obligatorio" })}
              error={!!errors?.name}
              helperText={errors?.name?.message?.toString()}
              required
              margin="normal"
              fullWidth
              id="name"
              label="Nombre"
              name="name"
              defaultValue={" "}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("email")}
              error={!!errors?.email}
              helperText={errors?.email?.message?.toString()}
              margin="normal"
              fullWidth
              id="email"
              label="Correo"
              name="email"
              defaultValue={" "}
            />
          </Grid>
        </Grid>
      </Box>
    </Edit>
  );
};
