import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  useAutocomplete,
  List,
  DataGrid,
  esES,
  Button,
  RefreshButton,
  Edit,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IEmployee, IPastedMaterial } from "interfaces";
import { Add } from "@mui/icons-material";

import {
  useList,
  useModal,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import { materialColumns } from "../enabled/grid_columns";
import _ from "lodash-es";
import { EnabledMaterialModal } from "../enabled/modals/materials";

export const PastedEdit: React.FC = () => {
  const params = useParams();
  const { id } = params as any;
  const [materials, setMaterials] = React.useState<IPastedMaterial[]>([]);
  const [currentMaterial, setCurrentMaterial] =
    React.useState<IPastedMaterial>();
  const [materialsDeleted, setMaterialsDeleted] = React.useState<any[]>([]);
  const [materialsAdd, setMaterialsAdd] = React.useState(false);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish, queryResult },
    saveButtonProps,
    control,
    formState: { errors },
    handleSubmit,
    // getValues,
  } = useForm({
    refineCoreProps: {
      resource: "pasteds/edit",
      redirect: false,
      id: id,
      metaData: {
        populate: ["employee", "order"],
      },
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("pasteds.edit.success"),
          type: "success",
        };
      },
    },
  });

  const data = queryResult?.data?.data;

  const materialsUseList = useList<IPastedMaterial>({
    resource: "pasted-materials",
    queryOptions: {
      cacheTime: 0,
    },
    config: {
      filters: [
        {
          field: "pasted",
          operator: "" as any,
          value: id,
        },
      ],
    },
    metaData: {
      populate: ["material"],
    },
  });

  const {
    data: materialsData,
    isFetched: materialsIsFetched,
    isLoading: materialsIsLoading,
  } = materialsUseList;

  useEffect(() => {
    if (materialsIsFetched) {
      setMaterials(materialsData?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialsIsFetched]);

  const onSubmit = (newData: any) => {
    onFinish({
      ...newData,
      order: data?.order?.id,
      materials: materials,
      materials_deleted: materialsDeleted,
    });
  };

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: "employees",
    filters: [{ field: "active", operator: "eq", value: true }],
    onSearch: (search) => [
      {
        field: "name",
        operator: "contains",
        value: search,
      },
    ],
  });

  const {
    show: showMaterialModal,
    close: closeMaterialModal,
    visible: visibleMaterialModal,
  } = useModal();

  const handleAddMaterial = (
    data: IPastedMaterial,
    another: boolean = false
  ) => {
    if (!another) {
      closeMaterialModal();
    }
    if (data?.action === "create") {
      const materialId = uuid();
      const newMaterial = {
        id: materialId,
        material: data.material,
        delivered_weight: data.delivered_weight,
        delete: handleDeleteMaterial,
        update: handleUpdateMaterial,
        action: "create",
      };
      setMaterials([...materials, newMaterial]);
    } else if (data?.action === "update") {
      const newMaterials = materials.map((m) => {
        if (m.id === data.id) {
          return {
            ...m,
            material: data.material,
            delivered_weight: data.delivered_weight,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return m;
      });
      setMaterials(newMaterials);
      setCurrentMaterial(undefined);
    }
  };

  const handleDeleteMaterial = (id: string) => {
    const newMaterials = materials.filter((m) => m.id !== id);
    setMaterials(newMaterials);
    if (!_.includes(id, "-")) {
      setMaterialsDeleted([...materialsDeleted, id]);
    }
  };

  const handleUpdateMaterial = (data: IPastedMaterial, canAdd: boolean) => {
    setCurrentMaterial(data);
    setMaterialsAdd(canAdd);
    showMaterialModal();
  };

  const materialsWithActions = materials?.map((m) => ({
    ...m,
    delete: handleDeleteMaterial,
    update: handleUpdateMaterial,
    action: "none",
  }));

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: formLoading,
        onClick: handleSubmit(onSubmit),
      }}
      resource="pasteds"
      headerButtons={() => {
        return (
          <RefreshButton
            resourceNameOrRouteName="pasteds"
            recordItemId={id}
            metaData={{ populate: ["employee"] }}
          />
        );
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="employee"
              rules={{ required: "El campo empleado es obligatorio." }}
              defaultValue={null as any}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return (
                        autocompleteProps?.options?.find(
                          (p) => p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        value === undefined ||
                        option.id.toString() === value.id.toString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empleado"
                        margin="normal"
                        variant="outlined"
                        error={!!errors.employee}
                        helperText={errors.employee?.message?.toString()}
                        required
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Materiales"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showMaterialModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
              canCreate={false}
            >
              <DataGrid
                rows={materialsWithActions}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={materialColumns}
                loading={materialsIsLoading}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
            <EnabledMaterialModal
              visible={visibleMaterialModal}
              close={closeMaterialModal}
              title={"Nuevo material"}
              setData={handleAddMaterial}
              data={currentMaterial}
              canAdd={materialsAdd}
              setCanAdd={setMaterialsAdd}
            />
          </Grid>
        </Grid>
      </Box>
    </Edit>
  );
};
