import { Button, DeleteButton, GridColumns, Stack } from "@pankod/refine-mui";
import { AddOutlined, EditOutlined, SaveOutlined } from "@mui/icons-material";
import { DateField } from "@pankod/refine-mui";

export const productColumns: GridColumns = [
  {
    field: "product",
    headerName: "Producto",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.product.code + " - " + params.row.product.name;
    },
  },
  {
    field: "quantity_pieces",
    headerName: `Cantidad\nPiezas`,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "weight_pieces",
    headerName: `Peso\nPiezas`,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "Acciones",
    headerAlign: "center",
    field: "actions",
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <Button>
            <AddOutlined
              color="info"
              fontSize="small"
              onClick={() => row.update(row, true)}
            />
          </Button>
          <Button>
            <EditOutlined
              fontSize="small"
              onClick={() => row.update(row, false)}
            />
          </Button>
          <DeleteButton
            size="small"
            hideText
            onClick={() => row.delete(row.id)}
          />
        </Stack>
      );
    },
  },
];

export const materialColumns: GridColumns = [
  {
    field: "material",
    headerName: "Material",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.material.code + " - " + params.row.material.name;
    },
  },
  {
    field: "delivered_weight",
    headerName: `Peso\nEntregado`,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "Acciones",
    headerAlign: "center",
    field: "actions",
    minWidth: 180,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <Button>
            <AddOutlined
              color="info"
              fontSize="small"
              onClick={() => row.update(row, true)}
            />
          </Button>
          <Button>
            <EditOutlined
              fontSize="small"
              onClick={() => row.update(row, false)}
            />
          </Button>
          <DeleteButton
            size="small"
            hideText
            onClick={() => row.delete(row.id)}
          />
        </Stack>
      );
    },
  },
];

export const excessColumns: GridColumns = [
  {
    field: "employee",
    headerName: "Empleado",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.employee.name;
    },
  },
  {
    field: "material",
    headerName: "Sobrante",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "weight",
    headerName: `Peso\nSobrante`,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "Acciones",
    headerAlign: "center",
    field: "actions",
    minWidth: 180,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <Button>
            <AddOutlined
              color="info"
              fontSize="small"
              onClick={() => row.update(row, true)}
            />
          </Button>
          <Button>
            <EditOutlined
              fontSize="small"
              onClick={() => row.update(row, false)}
            />
          </Button>
          <DeleteButton
            size="small"
            hideText
            onClick={() => row.delete(row.id)}
          />
        </Stack>
      );
    },
  },
];

export const finishedPiecesColumns: GridColumns = [
  {
    field: "createdAt",
    headerName: "Fecha",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 130,
    renderCell: (params) => {
      if (!params.row.createdAt) return null;
      return (
        <DateField
          value={params.row.createdAt}
          format={"DD / MMM / YYYY"}
          locales={"es-mx"}
          style={{ textTransform: "uppercase" }}
        />
      );
    },
  },
  {
    field: "employee",
    headerName: "Empleado",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 150,
    renderCell: (params) => {
      return params.row.employee.name;
    },
  },
  {
    field: "qty",
    headerName: "Cant.",
    headerAlign: "center",
    flex: 1,
    sortable: false,
    filterable: false,
    align: "center",
  },
  {
    field: "real_weight",
    headerName: "P. R.",
    headerAlign: "center",
    flex: 1,
    sortable: false,
    filterable: false,
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      return params.row.status ? (
        <SaveOutlined color="success" />
      ) : (
        <SaveOutlined color="error" />
      );
    },
  },
];

export const finishedPiecesSimpleColumns: GridColumns = [
  {
    field: "createdAt",
    headerName: "Fecha",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 130,
    renderCell: (params) => {
      if (!params.row.createdAt) return null;
      return (
        <DateField
          value={params.row.createdAt}
          format={"DD / MMM / YYYY"}
          locales={"es-mx"}
          style={{ textTransform: "uppercase" }}
        />
      );
    },
  },
  {
    field: "employee",
    headerName: "Empleado",
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 150,
    renderCell: (params) => {
      return params.row.employee.name;
    },
  },
  {
    field: "qty",
    headerName: "Cant.",
    headerAlign: "center",
    flex: 1,
    sortable: false,
    filterable: false,
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      return params.row.status ? (
        <SaveOutlined color="success" />
      ) : (
        <SaveOutlined color="error" />
      );
    },
  },
];
