import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  List,
  DataGrid,
  esES,
  Button,
  Create,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { IOrder, ISentInvoice } from "interfaces";
import { Add } from "@mui/icons-material";

import {
  HttpError,
  useModal,
  useNavigation,
  useOne,
  useTranslate,
} from "@pankod/refine-core";
import { v4 as uuid } from "uuid";
import { invoicesColumns } from "./grid_columns";
import _ from "lodash-es";
import { InvoicesModal } from "../enabled/modals/invoices/index";
import { useLocation } from "react-router-dom";

type LocationState = {
  recordItemId?: string;
};

export const SentCreate: React.FC = () => {
  const [invoices, setInvoices] = React.useState<ISentInvoice[]>([]);
  const [currentInvoice, setCurrentInvoice] = React.useState<ISentInvoice>();
  const [invoicessDeleted, setInvoicesDeleted] = React.useState<any[]>([]);
  const [invoicesAdd, setInvoicesAdd] = React.useState(false);
  const [totalPiecesDelivered, setTotalPiecesDelivered] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const { list } = useNavigation();
  const translate = useTranslate();

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      resource: "sents/create", // TODO: change to sent
      redirect: false,
      onMutationSuccess: () => {
        list("orders/open");
      },
      successNotification: () => {
        return {
          description: translate("notifications.success"),
          message: translate("sents.create.success"), // TODO: change to sent
          type: "success",
        };
      },
    },
  });

  const location = useLocation();
  const { recordItemId } = location.state as LocationState;

  const { data: dataOrder, isLoading: isLoadingOrder } = useOne<
    IOrder,
    HttpError
  >({
    resource: "orders",
    id: recordItemId || 0,
    queryOptions: {
      enabled: !!recordItemId,
    },
  });

  const onSubmit = (newData: any) => {
    onFinish({
      ...newData,
      order: recordItemId,
      totalPiecesDelivered: totalPiecesDelivered,
      totalAmount: totalAmount,
      invoices: invoices,
    });
  };

  const {
    show: showInvoiceModal,
    close: closeInvoiceModal,
    visible: visibleInvoiceModal,
  } = useModal();

  const handleAddInvoice = (data: ISentInvoice, another: boolean = false) => {
    if (!another) {
      closeInvoiceModal();
    }
    if (data?.action === "create") {
      const invoiceId = uuid();
      const newInvoice = {
        id: invoiceId,
        invoice: data.invoice,
        amount: data.amount,
        qty: data.qty,
        delete: handleDeleteInvoice,
        update: handleUpdateInvoice,
        action: "create",
      };
      setInvoices([...invoices, newInvoice]);
    } else if (data?.action === "update") {
      const newInvoices = invoices.map((i) => {
        if (i.id === data.id) {
          return {
            ...i,
            invoice: data.invoice,
            amount: data.amount,
            qty: data.qty,
            action: _.includes(data?.id?.toString(), "-") ? "create" : "update",
          };
        }
        return i;
      });
      setInvoices(newInvoices);
      setCurrentInvoice(undefined);
    }
  };

  const handleDeleteInvoice = (id: string) => {
    const newInvoices = invoices.filter((m) => m.id !== id);
    setInvoices(newInvoices);
    if (!_.includes(id, "-")) {
      setInvoicesDeleted([...invoicessDeleted, id]);
    }
  };

  const handleUpdateInvoice = (data: ISentInvoice, canAdd: boolean) => {
    setCurrentInvoice(data);
    setInvoicesAdd(canAdd);
    showInvoiceModal();
  };

  const invoicesWithActions = invoices?.map((i) => ({
    ...i,
    delete: handleDeleteInvoice,
    update: handleUpdateInvoice,
    action: "none",
  }));

  useEffect(() => {
    if (invoices.length) {
      const { totalPiecesDelivered, totalAmount } = invoices.reduce(
        (acc, curr) => {
          const qty = +(curr.qty || 0);
          const amount = +(curr.amount || 0);
          return {
            totalPiecesDelivered: acc.totalPiecesDelivered + qty,
            totalAmount: acc.totalAmount + amount,
          };
        },
        { totalPiecesDelivered: 0, totalAmount: 0 }
      );

      setTotalPiecesDelivered(totalPiecesDelivered);
      setTotalAmount(totalAmount);
    }
  }, [invoices]);

  return (
    <Create
      isLoading={formLoading || isLoadingOrder}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: formLoading || isLoadingOrder,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextField
              margin="normal"
              fullWidth
              id="total_amount"
              label="Monto Total"
              name="total_amount"
              // defaultValue={0}
              value={(totalAmount || 0).toFixed(2)}
              className="readonly"
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              margin="normal"
              fullWidth
              id="total_pieces"
              label="Pz. Totales"
              name="total_pieces"
              // defaultValue={" "}
              value={dataOrder?.data?.total_pieces || 0}
              className="readonly"
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              margin="normal"
              fullWidth
              id="total_pieces_delivered"
              label="Pz. Entregadas"
              name="total_pieces_delivered"
              // defaultValue={0}
              value={totalPiecesDelivered || 0}
              className="readonly"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <List
              breadcrumb={false}
              title={"Facturas"}
              headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showInvoiceModal();
                    }}
                  >
                    Agregar
                  </Button>
                </>
              )}
              canCreate={false}
            >
              <DataGrid
                rows={invoicesWithActions}
                hideFooter={true}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={invoicesColumns}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                }}
              />
            </List>
            <InvoicesModal
              visible={visibleInvoiceModal}
              close={closeInvoiceModal}
              title={"Nueva factura"}
              setData={handleAddInvoice}
              data={currentInvoice}
              canAdd={invoicesAdd}
              setCanAdd={setInvoicesAdd}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
